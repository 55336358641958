import { unique } from "./array";

export interface Equal<T> {
  (a: T, b: T): boolean;
}

export function identityEqual<T>(a: T, b: T) {
  return Object.is(a, b);
}

export function arrayEqual<T>(element: Equal<T>): Equal<readonly T[]> {
  return (a, b) =>
    a.length === b.length && a.every((e, index) => element(e, b[index]));
}

export function arraysEqualIgnoreOrder<T>(
  a: T[],
  b: T[],
  uniqueKey?: (item: T) => string,
): boolean {
  if (a.length !== b.length) {
    return false;
  }

  if (uniqueKey) {
    const setA = new Set(a.map(uniqueKey));
    return b.every((item) => setA.has(uniqueKey(item)));
  } else {
    const setA = new Set(a.map(uniqueKey || ((item) => item)));
    return b.every((item) => setA.has(item));
  }
}

export const booleanEqual: Equal<boolean> = identityEqual;

export const dateEqual: Equal<Date> = (a, b) => a.getTime() === b.getTime();

export function objectEqual<T>(properties: {
  [K in keyof T]: Equal<T[K]>;
}): Equal<T> {
  const p = <[keyof T, Equal<any>][]>Object.entries(properties);
  return (a, b) => p.every(([key, equal]) => equal(a[key], b[key]));
}

export function optionalEqual<T>(inner: Equal<T>): Equal<T | undefined> {
  return (a, b) =>
    a === undefined ? b === undefined : b !== undefined && inner(a, b);
}

export function mapEqual<K, V>(valueEqual: Equal<V>): Equal<Map<K, V>> {
  return (a, b) => {
    if (a.size !== b.size) {
      return false;
    }
    for (const [key, value] of a) {
      if (!b.has(key) || !valueEqual(value, b.get(key)!)) {
        return false;
      }
    }
    return true;
  };
}

export function nullableEqual<T>(inner: Equal<T>): Equal<T | null> {
  return (a, b) => (a === null ? b === null : b !== null && inner(a, b));
}

export const numberEqual: Equal<number> = identityEqual;

export const stringCaseInsensitiveEqual: Equal<string> = (a, b) =>
  !a.localeCompare(b, undefined, { sensitivity: "accent" });

export const symbolEqual: Equal<symbol> = identityEqual;

export const stringEqual: Equal<string> = identityEqual;

export const urlEqual: Equal<URL> = (a, b) => String(a) === String(b);

export const idEqual: Equal<{ _id: string }> = (a, b) => a._id === b._id;

/**
 * Treats {a: undefined, b: 1} as equal to {b: 1}
 */
export const deepIntersectionEquals = <T>(a: T, b: T): boolean => {
  if (a === b) {
    return true;
  }

  if (
    a === null ||
    b === null ||
    typeof a !== "object" ||
    typeof b !== "object"
  ) {
    return false;
  }

  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  } else if (Array.isArray(a) && Array.isArray(b)) {
    return (
      a.length === b.length &&
      a.every((item, index) => deepIntersectionEquals(item, b[index]))
    );
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  const uniqueKeys = unique([...keysA, ...keysB]);

  for (const key of uniqueKeys) {
    if (!deepIntersectionEquals((a as any)[key], (b as any)[key])) {
      return false;
    }
  }

  return true;
};
