import * as classNames from "classnames";
import { ReactNode, memo } from "react";
import { Flex } from "./flex";
import * as labeledInputCss from "./labeled-input.module.css";
import { Text } from "./text";

export enum LabelTheme {
  NORMAL = "Normal",
  LIGHT = "Light",
  THIN = "Thin",
  THIN_BOLD = "Thin Bold",
}

export enum LabelPosition {
  ABOVE = "above",
  LEFT = "left",
  RIGHT = "right",
}

export enum LabelOrientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

const labelPositionClass = new Map<LabelPosition, string>();
labelPositionClass.set(LabelPosition.ABOVE, labeledInputCss.vertical);
labelPositionClass.set(LabelPosition.LEFT, labeledInputCss.horizontal);
labelPositionClass.set(LabelPosition.RIGHT, labeledInputCss.horizontal);

export const LabeledInput = memo(function LabeledInput({
  label,
  id,
  children,
  description,
  position = LabelPosition.ABOVE,
  errors = [],
  theme = LabelTheme.NORMAL,
  orientation = LabelOrientation.HORIZONTAL,
  alignmentOverride,
  className,
}: {
  id?: string;
  label: ReactNode;
  children: ReactNode;
  description?: ReactNode | ReactNode[];
  position?: LabelPosition;
  theme?: LabelTheme;
  orientation?: LabelOrientation;
  errors?: string[];
  alignmentOverride?: "center" | "start" | "end"; // temporary escape hatch for alignment issues, deserves a better solution
  className?: string;
}) {
  return (
    <div
      className={classNames(
        labeledInputCss.container,
        labelPositionClass.get(position),
        className,
        {
          [labeledInputCss.light]: theme === LabelTheme.LIGHT,
          [labeledInputCss.thin]: theme === LabelTheme.THIN,
          [labeledInputCss.thinBold]: theme === LabelTheme.THIN_BOLD,
        },
      )}
      style={{
        alignItems: alignmentOverride,
      }}
    >
      {position === LabelPosition.RIGHT ? (
        orientation == LabelOrientation.HORIZONTAL ? (
          <>
            {children}
            {description && (
              <Text as="div" fontSize="xs" textColor="tertiary">
                {description}
              </Text>
            )}
            {label && (
              <label className={labeledInputCss.label} htmlFor={id}>
                {label}
              </label>
            )}
          </>
        ) : (
          <>
            {children}
            <Flex dir="column" gap="xxs">
              {label && (
                <label className={labeledInputCss.label} htmlFor={id}>
                  {label}
                </label>
              )}
              {description && (
                <Text as="div" fontSize="xs" textColor="tertiary">
                  {description}
                </Text>
              )}
            </Flex>
          </>
        )
      ) : undefined}
      {[LabelPosition.LEFT, LabelPosition.ABOVE].includes(position) && (
        <>
          {label && (
            <label className={labeledInputCss.label} htmlFor={id}>
              {label}
            </label>
          )}
          {children}
          {description && (
            <Text as="div" fontSize="xs" textColor="tertiary">
              {description}
            </Text>
          )}
        </>
      )}
      {errors.map((error, index) => (
        <div className={labeledInputCss.error} key={index}>
          {error}
        </div>
      ))}
    </div>
  );
});
