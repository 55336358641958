import {
  AddressSchema,
  CompensationMethodType,
  DraftReturnSchema,
  DraftReturnShipmentSchema,
  InteractiveMapLocationSchema,
  ProvisionType,
  ReturnTotalCalculationsOutputSchema,
  ReturnTypeName,
  SettlementSchema,
  ShipmentMethodSchema,
} from "@redotech/redo-model/draft-return";
import {
  NewExchangeItemSchema,
  PendingReturnItemSchema,
  ReturnableItemSchema,
} from "@redotech/redo-model/draft-return-items";
import { ClientOptions, createRpcClient } from "@redotech/rpc/client";
import { InferRpcDefinition } from "@redotech/rpc/definition";
import { zExt } from "@redotech/rpc/ext";
import { getWarrantyEligibleProductsSchema as getWarrantyEligibleProductsSchemaServer } from "@redotech/server/utils/warranties/get-warranty-eligible-products-schema";
import { submitWarrantyRegistrationSchema as submitWarrantyRegistrationSchemaServer } from "@redotech/server/utils/warranties/submit-warranty-registration-schema";
import { z } from "zod";
import { fulfillmentOrderSchema } from "./schema/fulfillment-order-schema";
import { getSharedListRpcSchema } from "./schema/get-shared-list-schema";

const AddItemPendingItemSchema = PendingReturnItemSchema.omit({
  id: true,
  manualReview: true,
  flagged: true,
  returnReason: true,
  returnOptions: true,
  selectedReturnOption: true,
});
export type AddItemPendingItem = z.infer<typeof AddItemPendingItemSchema>;

const changeAddressRpcSchema = {
  changeAddress: {
    input: z.object({
      draftReturnId: z.string(),
      newOrderAddress: AddressSchema.nullish(),
      returnAddress: AddressSchema.nullish(),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const createDraftReturnRpcSchema = {
  createDraftReturn: {
    input: z.object({
      orderId: zExt.objectId().nullish(),
      email: z.string().nullish(),
      address: AddressSchema.nullish(),
      returnType: z.nativeEnum(ReturnTypeName),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
  createOrderlessDraftReturn: {
    input: z.object({
      productIds: z.array(z.string()),
      warrantyId: z.string().nullish(),
      returnType: z.nativeEnum(ReturnTypeName),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const editReturnableItemRpcSchema = {
  editReturnableItem: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      item: ReturnableItemSchema,
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
      newItemId: z.string(),
    }),
  },
};

const getDraftReturnRpcSchema = {
  getDraftReturn: {
    input: z.object({
      draftReturnId: zExt.objectId(),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema.nullable(),
    }),
  },
};

const getShippingEstimateRpcSchema = {
  getShippingEstimate: {
    input: z.object({
      draftReturnId: zExt.objectId(),
    }),
    output: z.array(DraftReturnShipmentSchema),
  },
};

const getStripePublicKeyRpcSchema = {
  getStripePublicKey: {
    input: z.object({}).nullish(),
    output: z.object({
      publicKey: z.string(),
    }),
  },
};

const selectShipmentMethodRpcSchema = {
  selectShipmentMethod: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      shipmentMethod: ShipmentMethodSchema,
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const setCompensationMethodRpcSchema = {
  setCompensationMethod: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      selectedMethod: z.nativeEnum(CompensationMethodType),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const addReturnItemRpcSchema = {
  addReturnItem: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      previousPendingItemId: z.string().optional(),
      pendingItem: AddItemPendingItemSchema,
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const removeReturnItemRpcSchema = {
  removeReturnItem: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      pendingItemId: z.string(),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const submitReturnRpcSchema = {
  submitReturn: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      settlement: SettlementSchema.nullish(),
      provisionType: ProvisionType,
      paymentToken: z.string().nullish(),
    }),
    output: z.object({
      returnId: z.string(),
    }),
  },
};

const submitReturnNewRpcSchema = {
  submitReturnNew: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      settlement: SettlementSchema.nullish(),
      provisionType: ProvisionType,
      paymentToken: z.string().nullish(),
    }),
    output: z.object({
      returnId: z.string(),
    }),
  },
};

const rejectReturnItemsRpcSchema = {
  rejectReturnItems: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      returnableItemIds: z.array(z.string()),
      rejectReason: z.string(),
      rejectMessage: z.string(),
    }),
    output: z.object({}),
  },
};

const addNewItemsRpcSchema = {
  addNewItems: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      items: z.array(NewExchangeItemSchema),
      clearExistingItems: z.boolean().optional(),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const removeNewItemsRpcSchema = {
  removeNewItems: {
    input: z.object({
      draftReturnId: zExt.objectId(),
      variantIds: z.array(z.string()),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const verifyAddressRpcSchema = {
  verifyAddress: {
    input: z.object({
      address: AddressSchema,
    }),
    output: z.object({
      verifiedAddress: AddressSchema,
      verifications: z.object({
        delivery: z.object({
          success: z.boolean(),
        }),
      }),
    }),
  },
};

const calculateReturnTotalsRpcSchema = {
  calculateReturnTotals: {
    input: z.object({
      draftReturnId: zExt.objectId(),
    }),
    output: z.object({
      calculations: ReturnTotalCalculationsOutputSchema,
    }),
  },
};

const getLocationsRpcSchema = {
  getLocations: {
    input: z.object({
      draftReturnId: zExt.objectId(),
    }),
    output: z.object({
      locations: z.array(InteractiveMapLocationSchema),
    }),
  },
};

const getOrderIdSchema = {
  getOrderId: {
    input: z.object({ shopifyId: z.string() }),
    output: z.object({ id: zExt.objectId() }),
  },
};

const toggleSelectReturnItemSchema = {
  toggleSelectReturnItem: {
    input: z.object({
      draftReturnId: z.string(),
      returnItemId: z.string(),
    }),
    output: z.object({
      draftReturn: DraftReturnSchema,
    }),
  },
};

const getSatisfactionResponseRpcSchema = {
  getSatisfactionResponse: {
    input: z.object({
      conversationId: z.string(),
    }),
    output: z.object({
      rating: z.number().nullish(),
      comment: z.string().nullish(),
      createdAt: z.date().nullish(),
    }),
  },
};

const saveSatisfactionResponseSchema = {
  saveSatisfactionResponse: {
    input: z.object({
      channel: z.string(),
      comment: z.string(),
      conversationId: z.string(),
      rating: z.number(),
    }),
    output: z.object({
      success: z.boolean(),
    }),
  },
};

const getWarrantyRegistrationFlowSchema = {
  getWarrantyRegistrationFlow: {
    input: z.object({}).nullish(),
    output: z.object({
      draftWarrantyRegistration: DraftReturnSchema,
    }),
  },
};

const getWarrantyEligibleProductsSchema = {
  getWarrantyEligibleProducts: getWarrantyEligibleProductsSchemaServer,
};

const submitWarrantyRegistrationSchema = {
  submitWarrantyRegistration: submitWarrantyRegistrationSchemaServer,
};

type CustomerPortalRpcType = typeof changeAddressRpcSchema &
  typeof createDraftReturnRpcSchema &
  typeof editReturnableItemRpcSchema &
  typeof getDraftReturnRpcSchema &
  typeof getShippingEstimateRpcSchema &
  typeof getStripePublicKeyRpcSchema &
  typeof selectShipmentMethodRpcSchema &
  typeof setCompensationMethodRpcSchema &
  typeof addReturnItemRpcSchema &
  typeof removeReturnItemRpcSchema &
  typeof submitReturnRpcSchema &
  typeof submitReturnNewRpcSchema &
  typeof rejectReturnItemsRpcSchema &
  typeof addNewItemsRpcSchema &
  typeof removeNewItemsRpcSchema &
  typeof verifyAddressRpcSchema &
  typeof calculateReturnTotalsRpcSchema &
  typeof getLocationsRpcSchema &
  typeof getSatisfactionResponseRpcSchema &
  typeof saveSatisfactionResponseSchema &
  typeof getSharedListRpcSchema &
  typeof getWarrantyEligibleProductsSchema &
  typeof getWarrantyRegistrationFlowSchema &
  typeof submitWarrantyRegistrationSchema &
  typeof fulfillmentOrderSchema &
  typeof getOrderIdSchema &
  typeof toggleSelectReturnItemSchema;

export const customerPortalRPC: CustomerPortalRpcType = {
  ...changeAddressRpcSchema,
  ...createDraftReturnRpcSchema,
  ...editReturnableItemRpcSchema,
  ...getDraftReturnRpcSchema,
  ...getShippingEstimateRpcSchema,
  ...getStripePublicKeyRpcSchema,
  ...selectShipmentMethodRpcSchema,
  ...setCompensationMethodRpcSchema,
  ...addReturnItemRpcSchema,
  ...removeReturnItemRpcSchema,
  ...submitReturnRpcSchema,
  ...submitReturnNewRpcSchema,
  ...rejectReturnItemsRpcSchema,
  ...addNewItemsRpcSchema,
  ...removeNewItemsRpcSchema,
  ...verifyAddressRpcSchema,
  ...calculateReturnTotalsRpcSchema,
  ...getLocationsRpcSchema,
  ...getSatisfactionResponseRpcSchema,
  ...saveSatisfactionResponseSchema,
  ...getSharedListRpcSchema,
  ...getWarrantyEligibleProductsSchema,
  ...getWarrantyRegistrationFlowSchema,
  ...submitWarrantyRegistrationSchema,
  ...fulfillmentOrderSchema,
  ...getOrderIdSchema,
  ...toggleSelectReturnItemSchema,
};

export type CustomerPortalRpcDefiniton = InferRpcDefinition<
  typeof customerPortalRPC
>;

export const createCustomerPortalClient = (
  options: ClientOptions<typeof customerPortalRPC>,
) => createRpcClient(customerPortalRPC, options);

export type CustomerPortalClient = ReturnType<
  typeof createCustomerPortalClient
>;
