import {
  Bijection,
  bijectionCompose,
  bijectionInvert,
  bijectionRef,
} from "@redotech/util/bijection";
import { base64Encoding, latin1Encoding } from "@redotech/util/encoding";
import { STRING_ENUM } from "@redotech/util/enum";
import { Tuple } from "@redotech/util/type";
import { Json, JsonObject, jsonStringFormat } from "./json";

export interface JsonFormat<T> extends Bijection<Json, T> {}

export class JsonFormatError extends Error {}

export const arrayBufferJsonFormat: JsonFormat<ArrayBuffer> = bijectionCompose(
  bijectionRef(() => stringJsonFormat),
  bijectionInvert(latin1Encoding),
);

export const arrayBufferBase64JsonFormat: JsonFormat<ArrayBuffer> =
  bijectionCompose(
    bijectionRef(() => stringJsonFormat),
    base64Encoding,
  );

export function arrayJsonFormat<T>(
  elementFormat: JsonFormat<T>,
): JsonFormat<T[]> {
  return {
    read(json: Json) {
      if (!(json instanceof Array)) {
        throw new JsonFormatError("Expected array");
      }
      return json.map((element) => elementFormat.read(element));
    },
    write(value: T[]) {
      return value.map((element) => elementFormat.write(element));
    },
  };
}

export function nullableJsonFormat<T>(
  format: JsonFormat<T>,
): JsonFormat<T | null> {
  return {
    read(json: Json) {
      if (json === null) {
        return null;
      }
      return format.read(json);
    },
    write(value: T | null) {
      if (value === null) {
        return null;
      }
      return format.write(value);
    },
  };
}

export const booleanJsonFormat: JsonFormat<boolean> = {
  read(json: Json) {
    if (typeof json !== "boolean") {
      throw new JsonFormatError("Expected boolean");
    }
    return json;
  },
  write(value: boolean) {
    return value;
  },
};

export const numberJsonFormat: JsonFormat<number> = {
  read(json: Json) {
    if (typeof json !== "number") {
      throw new JsonFormatError("Expected number");
    }
    return json;
  },
  write(value: number) {
    return value;
  },
};

export function objectJsonFormat<T extends object>(
  properties_: {
    [K in keyof T as undefined extends T[K] ? never : K]: JsonFormat<T[K]>;
  },
  optionalProperties_: {
    [K in keyof T as undefined extends T[K] ? K : never]-?: JsonFormat<
      Exclude<T[K], undefined>
    >;
  },
): JsonFormat<T> {
  const properties = <[keyof T, JsonFormat<any>][]>Object.entries(properties_);
  const optionalProperties = <[keyof T, JsonFormat<any>][]>(
    Object.entries(optionalProperties_)
  );

  return {
    read(json: Json) {
      if (typeof json !== "object" || json instanceof Array || !json) {
        throw new JsonFormatError("Expected object");
      }
      const result = <T>{};
      for (const [key, format] of properties) {
        if (!(key in json)) {
          throw new JsonFormatError(`Expected key: ${<string>key}`);
        }
        result[key] = format.read(json[key]);
      }
      for (const [key, format] of optionalProperties) {
        if (!(key in json)) {
          continue;
        }
        result[key] = format.read(json[key]);
      }
      return result;
    },
    write(value: T) {
      const json: JsonObject = {};
      for (const [key, format] of properties) {
        json[<string>key] = format.write(value[key]);
      }
      for (const [key, format] of optionalProperties) {
        if (value[key] === undefined) {
          continue;
        }
        json[<string>key] = format.write(value[key]);
      }
      return json;
    },
  };
}

export const stringJsonFormat: JsonFormat<string> = {
  read(json: Json) {
    if (typeof json !== "string") {
      throw new JsonFormatError("Expected string");
    }
    return json;
  },
  write(value: string) {
    return value;
  },
};

export function typedStringJsonFormat<T extends string>(str: T): JsonFormat<T> {
  return {
    read(json: Json) {
      if (typeof json !== "string" || json !== str) {
        throw new JsonFormatError(`Expected ${str} string`);
      }
      return json as T;
    },
    write(value: string) {
      return value;
    },
  };
}

export function stringUnionJsonFormat<T extends readonly string[]>(
  values: T,
): JsonFormat<T[number]> {
  return bijectionCompose(stringJsonFormat, {
    read(value) {
      if (!values.includes(value as T[number])) {
        throw new JsonFormatError(`Invalid value: ${value}`);
      }
      return value as T[number];
    },
    write(value) {
      return value;
    },
  });
}

export function stringEnumJsonFormat<T extends string>(
  mapping: Record<string, T>,
): JsonFormat<T> {
  return {
    read(json: Json) {
      if (typeof json !== "string") {
        throw new JsonFormatError("Expected string");
      }
      if (Object.values(mapping).includes(json as T)) {
        return json as T;
      }
      throw new JsonFormatError(`Unrecognized enum value: ${json}`);
    },
    write(value: T) {
      return value;
    },
  };
}

export const dateJsonFormat = bijectionCompose(stringJsonFormat, {
  read: (string) => new Date(string),
  write: String,
});

const REGEXP_REGEXP = /^\/(.+)\/(\w+)?$/;

export const regexpJsonFormat: JsonFormat<RegExp> = bijectionCompose(
  stringJsonFormat,
  {
    read(string) {
      if (!string.startsWith("/")) {
        throw new JsonFormatError("Expected starting /");
      }
      const match = REGEXP_REGEXP.exec(string);
      if (!match) {
        throw new JsonFormatError("Invalid regular expression");
      }
      const [_, pattern, flags] = match;
      return new RegExp(pattern, flags);
    },
    write(regex) {
      return regex.toString();
    },
  },
);

export function stringMapJsonFormat<V>(
  value: JsonFormat<V>,
): JsonFormat<Map<string, V>> {
  return {
    read(json: Json) {
      if (typeof json !== "object" || json instanceof Array || !json) {
        throw new JsonFormatError("Expected object");
      }
      const result = new Map<string, V>();
      for (const key in json) {
        result.set(key, value.read(json[key]));
      }
      return result;
    },
    write(map: Map<string, V>) {
      const json: JsonObject = {};
      for (const [key, v] of map) {
        json[key] = value.write(v);
      }
      return json;
    },
  };
}

export function symbolJsonFormat<T extends symbol>(value: T): JsonFormat<T> {
  if (!value.description) {
    throw new JsonFormatError("Description required");
  }
  return bijectionCompose(stringJsonFormat, {
    read(string: string) {
      if (string !== value.description) {
        throw new JsonFormatError(`Expected value: ${value.description}`);
      }
      return value;
    },
    write(symbol: T) {
      if (value !== symbol) {
        throw new JsonFormatError(`Expected value: ${String(value)}`);
      }
      return value.description;
    },
  });
}

export function symbolEnumJsonFormat<T extends symbol>(
  values: Iterable<T>,
): JsonFormat<T> {
  const byName = new Map<string, T>();
  for (const value of values) {
    if (value.description === undefined) {
      throw new JsonFormatError("Description required");
    }
    if (byName.has(value.description)) {
      throw new JsonFormatError("Duplicate description");
    }
    byName.set(value.description, value);
  }
  return bijectionCompose(stringJsonFormat, {
    read(string: string) {
      const value = byName.get(string);
      if (!value) {
        throw new JsonFormatError(`Invalid value: ${string}`);
      }
      return value;
    },
    write(value: T) {
      return value.description!;
    },
  });
}

export function symbolUnionJsonFormat<T extends object>(
  jsonKey: string,
  valueKey: keyof T,
  formats: { [key: symbol]: JsonFormat<any> },
): JsonFormat<T> {
  for (const format of Object.getOwnPropertySymbols(formats)) {
    if (!format.description) {
      throw new Error("Symbol lacks description");
    }
  }
  const stringFormats: { [key: string]: JsonFormat<any> } = Object.fromEntries(
    Object.getOwnPropertySymbols(formats).map((key) => [
      key.description,
      formats[key],
    ]),
  );

  return {
    read(json: Json) {
      if (typeof json !== "object" || json instanceof Array || !json) {
        throw new JsonFormatError("Expected object");
      }
      if (!(jsonKey in json)) {
        throw new JsonFormatError(`Expected key: ${jsonKey}`);
      }
      const type = json[jsonKey];
      if (typeof type !== "string") {
        throw new JsonFormatError("Expected type to be string");
      }
      if (!(type in stringFormats)) {
        throw new JsonFormatError("Unrecognized type");
      }
      return stringFormats[type].read(json);
    },
    write(value: T) {
      const type = <symbol>value[valueKey];
      return formats[type].write(value);
    },
  };
}

/**
 * @param format1 MUST NOT BE ABLE TO SUCCEED if the type of the data is format2.
 *
 * Use with care.
 */
export function unionFormat<T, K>(
  format1: JsonFormat<T>,
  format2: JsonFormat<K>,
  determineFormat: (x: T | K) => JsonFormat<T> | JsonFormat<K>,
): JsonFormat<T | K> {
  return {
    read(json: Json) {
      try {
        return format1.read(json);
      } catch (error) {
        if (error instanceof JsonFormatError) {
          return format2.read(json);
        }
        throw error;
      }
    },
    write(value: T | K) {
      const format = determineFormat(value);
      return format.write(value as T & K);
    },
  };
}

export function enumUnionJsonFormat<T extends object, E extends STRING_ENUM>(
  jsonKey: string,
  valueKey: keyof T,
  formats: { [key in keyof E]: JsonFormat<any> },
): JsonFormat<T> {
  for (const format of Object.getOwnPropertySymbols(formats)) {
    if (!format.description) {
      throw new Error("Symbol lacks description");
    }
  }

  return {
    read(json: Json) {
      if (typeof json !== "object" || json instanceof Array || !json) {
        throw new JsonFormatError("Expected object");
      }
      if (!(jsonKey in json)) {
        throw new JsonFormatError(`Expected key: ${jsonKey}`);
      }
      const type = json[jsonKey];
      if (typeof type !== "string") {
        throw new JsonFormatError("Expected type to be string");
      }
      if (!(type in formats)) {
        throw new JsonFormatError("Unrecognized type");
      }
      return formats[type].read(json);
    },
    write(value: T) {
      const type = <keyof E>value[valueKey];
      return formats[type].write(value);
    },
  };
}

export function backwardsCompatibleFormat<OLD, NEW>({
  oldFormat,
  newFormat,
  upgrade,
}: {
  oldFormat: JsonFormat<OLD>;
  newFormat: JsonFormat<NEW>;
  upgrade: (old: OLD) => NEW;
}): JsonFormat<NEW> {
  return {
    read(json: Json) {
      try {
        return newFormat.read(json);
      } catch (error) {
        if (error instanceof JsonFormatError) {
          return upgrade(oldFormat.read(json));
        }
        throw error;
      }
    },
    write(value: NEW) {
      return newFormat.write(value);
    },
  };
}

export function jsonSerializer<T>(format: JsonFormat<T>): Bijection<string, T> {
  return bijectionCompose(jsonStringFormat, format);
}

export const temporalDurationJsonFormat = bijectionCompose(stringJsonFormat, {
  read(value) {
    return Temporal.Duration.from(value);
  },
  write: String,
});

export const temporalInstantJsonFormat = bijectionCompose(stringJsonFormat, {
  read(value) {
    return Temporal.Instant.from(value);
  },
  write: String,
});

export const temporalPlainDateJsonFormat = bijectionCompose(stringJsonFormat, {
  read(value) {
    return Temporal.PlainDate.from(value);
  },
  write: String,
});

export const temporalPlainTimeJsonFormat = bijectionCompose(stringJsonFormat, {
  read(value) {
    return Temporal.PlainTime.from(value);
  },
  write: String,
});

export const temporalZonedDateTimeJsonFormat = bijectionCompose(
  stringJsonFormat,
  {
    read(value) {
      return Temporal.ZonedDateTime.from(value);
    },
    write: String,
  },
);

export const urlJsonFormat: JsonFormat<URL> = bijectionCompose(
  stringJsonFormat,
  {
    read(string) {
      return new URL(string);
    },
    write(url) {
      return url.toString();
    },
  },
);

export function tupleJsonFormat<T, L extends number>(
  elementFormat: JsonFormat<T>,
  length: L,
): JsonFormat<Tuple<T, L>> {
  return bijectionCompose(arrayJsonFormat(elementFormat), {
    read(array) {
      if (array.length !== length) {
        throw new JsonFormatError(`Expected array of length ${length}`);
      }
      return array as Tuple<T, L>;
    },
    write(tuple) {
      return [...tuple];
    },
  });
}
