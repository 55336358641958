import { Big } from "big.js";

export type Serializable =
  | null
  | string
  | number
  | boolean
  | Big.Big
  | Date
  | Temporal.Instant
  | Serializable[]
  | readonly Serializable[]
  | Map<Serializable, Serializable>
  | { [key: string]: Serializable };

export function dehydrate(
  value: Serializable,
): number | string | boolean | null | object {
  if (
    value === null ||
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  ) {
    return value;
  } else if (value instanceof Big) {
    return { $$big: value.toString() };
  } else if (value instanceof Date) {
    return { $$date: value.toISOString() };
  } else if (value instanceof Temporal.Instant) {
    return { $$instant: value.epochMilliseconds };
  } else if (Array.isArray(value) || value instanceof Array) {
    // `instanceof Array` is necessary to narrow the readonly[] type - does not affect runtime behavior
    return value.map(dehydrate);
  } else if (value instanceof Map) {
    return {
      $$map: Array.from(value.entries()).map(([key, val]) => [
        dehydrate(key),
        dehydrate(val),
      ]),
    };
  } else if (typeof value === "object") {
    return Object.fromEntries(
      Object.entries(value).map(([key, val]) => [key, dehydrate(val)]),
    );
  }
  // FIXME Really, we should throw an error here because `value` is not a Serializable type.
  //   But we currently have RPC definitions that include "optional" parameters,
  //   even though we don't consider `undefined` to be serializable.
  //   TypeScript doesn't stop this because the `RPCDefinition` type doesn't require
  //   the input/output zod definitions to be Serializable. We should fix this.
  // throw new TypeError(`Cannot dehydrate value of type ${typeof value}`);
  return value;
}

// TODO What should this do if the network returns `undefined`? For example, if a given endpoint doesn't return a body.
export function hydrate(value: unknown): Serializable {
  switch (typeof value) {
    case "undefined":
    case "function":
    case "symbol":
    case "bigint":
      throw new TypeError(`Cannot hydrate value of type ${typeof value}`);
    case "string":
    case "number":
    case "boolean":
      return value;
    case "object": {
      if (value === null) {
        return value;
      }
      if (Array.isArray(value)) {
        return value.map(hydrate);
      }
      if (Object.keys(value).length === 1) {
        if ("$$big" in value) {
          const bigSource = value.$$big;
          if (typeof bigSource !== "string" && typeof bigSource !== "number") {
            throw new TypeError(
              `Cannot hydrate value of type { $$big: ${typeof bigSource} }`,
            );
          }
          return new Big(bigSource);
        } else if ("$$date" in value) {
          const dateSource = value.$$date;
          if (
            typeof dateSource !== "string" &&
            typeof dateSource !== "number"
          ) {
            throw new TypeError(
              `Cannot hydrate value of type { $$date: ${typeof dateSource} }`,
            );
          }
          return new Date(dateSource);
        } else if ("$$instant" in value) {
          const instantSource = value.$$instant;
          if (typeof instantSource !== "number") {
            throw new TypeError(
              `Cannot hydrate value of type { $$instant: ${typeof instantSource} }`,
            );
          }
          return Temporal.Instant.fromEpochMilliseconds(instantSource);
        } else if ("$$map" in value) {
          const mapSource = value.$$map;
          if (!Array.isArray(mapSource)) {
            throw new TypeError(
              `Cannot hydrate value of type { $$map: ${typeof mapSource} }`,
            );
          }
          if (
            mapSource.some(
              (element) => !Array.isArray(element) || element.length !== 2,
            )
          ) {
            throw new TypeError(
              `Cannot hydrate value of type { $$map: ${mapSource} }`,
            );
          }
          return new Map(
            mapSource.map(([key, val]: [unknown, unknown]) => [
              hydrate(key),
              hydrate(val),
            ]),
          );
        }
      }
      return Object.fromEntries(
        Object.entries(value).map(([key, val]) => [key, hydrate(val)]),
      );
    }
  }
}
