import type { JsonFormat } from "@redotech/json/format";
import {
  arrayJsonFormat,
  booleanJsonFormat,
  dateJsonFormat,
  nullableJsonFormat,
  numberJsonFormat,
  objectJsonFormat,
  stringEnumJsonFormat,
  stringJsonFormat,
  symbolJsonFormat,
  symbolUnionJsonFormat,
  urlJsonFormat,
} from "@redotech/json/format";
import type { Json } from "@redotech/json/json";
import { Currency } from "@redotech/money/currencies";
import { bijectionRef } from "@redotech/util/bijection";
import * as memoize from "lodash/memoize";
import type { ConversationPlatform } from "./conversation";
import {
  DiscountCollection,
  DiscountCollectionsSelectionType,
  DiscountMinRequirementType,
  DiscountProduct,
  DiscountProductType,
  DiscountType,
  DiscountValueType,
  ExpirationType,
} from "./order-discount";
import type { ReturnType } from "./return";
import type { Action as Action_ } from "./return-flow/action";
import { actionJsonFormat as actionJsonFormat_ } from "./return-flow/action";
import type { Condition as Condition_ } from "./return-flow/condition";
import { conditionJsonFormat as conditionJsonFormat_ } from "./return-flow/condition";
import type { StoreCreditType } from "./return-flow/return-option";
import {
  ReturnOptionMethod,
  returnOptionMethodJsonFormat,
} from "./return-flow/return-option";
import type { TriggerType } from "./return-flow/trigger";
import { triggerTypeJsonFormat } from "./return-flow/trigger";
import type { ExchangeGroup } from "./team";

/** Adjustment for return value/upsell/restocking fees */
export interface Adjustment {
  flat: number;
  proportion: number;
}

export const adjustmentIdentity: Adjustment = { flat: 0, proportion: 1 };

const adjustmentJsonFormat: JsonFormat<Adjustment> = objectJsonFormat(
  { flat: numberJsonFormat, proportion: numberJsonFormat },
  {},
);

/**
 * Return flow
 */
export interface ReturnFlow {
  /** Steps */
  steps: Step[];
  publishedAt?: Date;
  _id?: string;
}

export interface ChatFlow {
  steps: ChatFlowStep[];
  publishedAt?: Date;
}

export interface DiscountFlow {
  steps: DiscountFlowStep[];
  publishedAt?: Date;
}

export interface RuleFlow {
  steps: RuleFlowStep[];
}

export interface StepIdFn {
  (key: StepFn): StepId;
}

export interface StepFn {
  (id: StepIdFn): Step;
}

export function stepsBuild(steps: Iterable<StepFn>): Step[] {
  const set = new Set(steps);
  let id: StepId = 0;
  const getId = memoize<(key: StepFn) => StepId>((step) => {
    if (!set.has(step)) {
      throw new Error(`Step is missing: ${step}`);
    }
    return id++;
  });
  const result: Step[] = [];
  for (const step of set) {
    const id = getId(step);
    result[id] = step(getId);
  }
  return result;
}

export const teamOffersExchanges = (returnFlow: ReturnFlow) => {
  for (const step of returnFlow.steps) {
    if (step.type === Step.RETURN) {
      for (const option of step.options) {
        if (option.method.type === ReturnOptionMethod.EXCHANGE) {
          return true;
        }
      }
    }
  }

  return false;
};

export const returnFlowJsonFormat: JsonFormat<ReturnFlow> = objectJsonFormat(
  {
    steps: arrayJsonFormat(bijectionRef(() => stepJsonFormat)),
  },
  {
    publishedAt: dateJsonFormat,
    _id: stringJsonFormat,
  },
);

export const chatFlowJsonFormat: JsonFormat<ChatFlow> = objectJsonFormat(
  {
    steps: arrayJsonFormat(
      bijectionRef(() => stepJsonFormat as JsonFormat<ChatFlowStep>),
    ),
  },
  {
    publishedAt: dateJsonFormat,
  },
);

export const discountFlowJsonFormat: JsonFormat<DiscountFlow> =
  objectJsonFormat(
    {
      steps: arrayJsonFormat(
        bijectionRef(() => stepJsonFormat as JsonFormat<DiscountFlowStep>),
      ),
    },
    {
      publishedAt: dateJsonFormat,
    },
  );

/**
 * AB test option
 */
export interface ABTestTreatment {
  /** Name */
  name: string;
  /** Percentage */
  percentage: number;
  /** Next step */
  next: StepId;
}

export const abTestTreatmentJsonFormat: JsonFormat<ABTestTreatment> =
  objectJsonFormat(
    {
      name: stringJsonFormat,
      next: bijectionRef(() => stepIdJsonFormat),
      percentage: numberJsonFormat,
    },
    {},
  );

/** Multiple choice answer */
export interface MultipleChoiceAnswer {
  name: string;
  step: StepId;
}

/** Input answer part */
export interface InputAnswerPart {
  message: string;
  /** Text or list of URLs */
  value: string | URL[];
  type: InputPartType;
}

const inputAnswerPartJsonFormat: JsonFormat<InputAnswerPart> = objectJsonFormat(
  {
    message: stringJsonFormat,
    value: {
      read(json: Json) {
        return typeof json === "string"
          ? json
          : (<string[]>json).map((json) => new URL(json));
      },
      write(value: string | URL[]) {
        return typeof value === "string"
          ? value
          : value.map((value) => value.toString());
      },
    },
    type: bijectionRef(() => inputPartTypeJsonFormat),
  },
  {},
);

/** Input answer */
export interface InputAnswer {
  parts: InputAnswerPart[];
}

export const inputAnswerJsonFormat: JsonFormat<InputAnswer> = objectJsonFormat(
  { parts: arrayJsonFormat(inputAnswerPartJsonFormat) },
  {},
);

/**
 * Question option
 */
export interface MultipleChoiceOption {
  /** Name */
  name: string;
  /** Next step */
  next: StepId;
}

export interface ChatMultipleChoiceOption {
  name: string;
  next: StepId | null;
  nextName: string | null;
}

export const multipleChoiceOptionJsonFormat: JsonFormat<MultipleChoiceOption> =
  objectJsonFormat(
    {
      name: stringJsonFormat,
      next: bijectionRef(() => stepIdJsonFormat),
    },
    {},
  );

export const chatMultipleChoiceOptionJsonFormat: JsonFormat<ChatMultipleChoiceOption> =
  objectJsonFormat(
    {
      name: stringJsonFormat,
      next: nullableJsonFormat(bijectionRef(() => stepIdJsonFormat)),
      nextName: nullableJsonFormat(stringJsonFormat),
    },
    {},
  );

/**
 * Info to collect
 */
export enum InfoToCollect {
  NAME = "name",
  EMAIL = "email",
  CUSTOM = "custom",
}

export const infoToCollectJsonFormat: JsonFormat<InfoToCollect> = <
  JsonFormat<InfoToCollect>
>stringJsonFormat;

/**
 * Conversation platform
 */
export const conversationPlatformJsonFormat: JsonFormat<ConversationPlatform> =
  <JsonFormat<ConversationPlatform>>stringJsonFormat;

export const emailToSendAsJsonFormat: JsonFormat<{
  email: string;
  name: string;
  integrationKind: string;
}> = objectJsonFormat(
  {
    name: stringJsonFormat,
    email: stringJsonFormat,
    integrationKind: stringJsonFormat,
  },
  {},
);

export enum Payer {
  /** Customer */
  CUSTOMER = "customer",
  /** Merchant */
  MERCHANT = "merchant",
  /** Redo */
  REDO = "redo",
}

export const payerJsonFormat: JsonFormat<Payer> = <JsonFormat<Payer>>(
  stringJsonFormat
);

// Null in case of green return when there is no label
export type UncoveredPayer =
  | typeof Payer.CUSTOMER
  | typeof Payer.MERCHANT
  | null;

export const uncoveredPayerJsonFormat: JsonFormat<UncoveredPayer> =
  stringJsonFormat as JsonFormat<UncoveredPayer>;

export interface AllowedExchangeTypes {
  sameItem: boolean;
  variant: boolean;
  exchangeGroups: boolean;
  advanced: boolean;
}

export interface ReturnOption {
  /** Return method */
  method: ReturnOptionMethod;
  /** Label payer */
  labelPayer: UncoveredPayer;
  /** Whether to ship the item back (i.e. not green return) */
  ship: boolean;
  /**
   * Which exchange types to allow the customer to choose.
   * Only relevant if method === ReturnOptionMethod.Exchange.
   */
  exchangeTypes: AllowedExchangeTypes;
  /** Whether to charge the customer for the new order label */
  chargeCustomerForNewOrderShipping?: boolean;
  /** Value of the return */
  adjustment: Adjustment;
}

export const exchangeTypesIdentity: AllowedExchangeTypes = {
  sameItem: true,
  variant: true,
  exchangeGroups: true,
  advanced: true,
};

export const returnOptionJsonFormat: JsonFormat<ReturnOption> =
  objectJsonFormat(
    {
      adjustment: adjustmentJsonFormat,
      labelPayer: uncoveredPayerJsonFormat,
      method: bijectionRef(() => returnOptionMethodJsonFormat),
      ship: booleanJsonFormat,
      exchangeTypes: objectJsonFormat<AllowedExchangeTypes>(
        {
          sameItem: booleanJsonFormat,
          variant: booleanJsonFormat,
          exchangeGroups: booleanJsonFormat,
          advanced: booleanJsonFormat,
        },
        {},
      ),
    },
    {
      chargeCustomerForNewOrderShipping: booleanJsonFormat,
    },
  );

export interface ClaimOption {
  /** Return method */
  method: ReturnOptionMethod;
  requireSendBack: boolean;
  chargeCustomerForNewOrderShipping?: boolean;
}

export const claimOptionJsonFormat: JsonFormat<ClaimOption> = objectJsonFormat(
  {
    method: returnOptionMethodJsonFormat,
    requireSendBack: booleanJsonFormat,
  },
  {
    chargeCustomerForNewOrderShipping: booleanJsonFormat,
  },
);

/**
 * Input configuration
 */
export enum InputValidation {
  REQUIRED = "required",
  OPTIONAL = "optional",
}

export const inputValidationJson: JsonFormat<InputValidation> =
  stringJsonFormat as JsonFormat<InputValidation>;

export enum FlowType {
  RETURN = "return",
  FINALIZE_RETURN = "finalize",
  FINALIZE_CLAIM = "finalize_claim",
  CLAIM = "claim",
  CHAT = "chat",
  EMAIL = "email",
  RULE = "rule",
  DISCOUNT = "discount",
  WARRANTY = "warranty",
  WARRANTY_REGISTRATION = "warranty_registration",
}

export type EmailStep =
  | Step.Trigger
  | Step.Wait
  | Step.SendEmail
  | Step.SendSms
  | Step.DoNothing;

// All steps should have a message field
export type ChatFlowStep =
  | Step.CloseChat
  | Step.CustomerResponse
  | Step.ChatMultipleChoice
  | Step.SendMessage
  | Step.AssignToAgent
  | Step.Reentry
  | Step.Condition
  | Step.SeeOrders;

export type RuleFlowStep =
  | Step.Trigger
  | Step.MultipleConditions
  | Step.MultipleActions;

export type DiscountFlowStep = Step.Discount | Step.Condition;

/** Step */
export type Step =
  | Step.Condition
  | Step.Input
  | Step.MultipleChoice
  | Step.Reject
  | Step.Return
  | Step.ManualReview
  | Step.Flag
  | Step.ExchangeShippingName
  | Step.DiscountCodeName
  | Step.OverrideAdjustment
  | Step.OverrideGreenReturn
  | Step.SubmitClaim
  | Step.SubmitWarranty
  | Step.SubmitRepair
  | Step.SubmitWarrantyRegistration
  | Step.Block
  | Step.CloseChat
  | Step.CustomerResponse
  | Step.ChatMultipleChoice
  | Step.SendMessage
  | Step.AssignToAgent
  | Step.Reentry
  | Step.Trigger
  | Step.Wait
  | Step.SendEmail
  | Step.SendSms
  | Step.SeeOrders
  | Step.Action
  | Step.MultipleActions
  | Step.MultipleConditions
  | Step.Discount
  | Step.FinalSaleReturn
  | Step.DoNothing
  | Step.ABTest;

export enum ExchangeTypeContext {
  VARIANT = "VARIANT",
  ADVANCED = "ADVANCED",
}

/** Exchange */
export interface ReturnExchange {
  /** Order details */
  order: any;
  /** The id of the new selected variant, if variant exchange */
  newVariantId: string | null;
  /** The exchange group used for the exchange, if there is one */
  exchangeGroup?: ExchangeGroup;
  /** The new item, if an exchange group was used */
  exchangeGroupItem?: ExchangeGroupItem;
  /** Account for different priced variants */
  accountForPriceDifference: boolean;
  /** Whether we should transfer the line item properties from the original item to the new item */
  transferLineItemProperties: boolean;
}

/** Credit */
export interface ReturnCredit {
  amount: number;
  type: StoreCreditType;
}

/** Refund */
export interface ReturnRefund {
  amount: number;
}

export interface ReturnLabel {
  /** Label payer */
  payer: Payer;
  /** Payment token, if paid by customer */
  paymentToken: string | null;
}

export enum ProvisionType {
  INSTANT = "instant",
  PROCESSED = "processed",
}

// Close, but not exactly the same as, the Address type from ./team.ts?
export interface Address {
  name: string;
  email: string;
  street1: string;
  street2?: string | null;
  state?: string | null;
  city: string;
  country: string;
  zip: string;
  phone?: string | null;
  returnerName?: string | null;
  returnerEmail?: string | null;
  _id?: string | null; // ID of location in Redo, for merchant return locations
}

export interface DiscountAllocation {
  discountedAmount: {
    amount: string;
    currencyCode: string;
  };
}

export interface GetOrdersRequest {
  email?: string;
  order_number?: string;
  emailOrZip?: string;
  getAllStatuses?: boolean;
  customerWidgetAuthToken?: string;
  page?: number;
}

// FedEx Pickup locations
export enum PickupLocation {
  FRONT_DOOR = "FRONT",
  BACK_DOOR = "REAR",
  SIDE_DOOR = "SIDE",
  OTHER = "NONE",
}

export const pickupLocationOptions: Map<
  PickupLocation,
  { value: PickupLocation; label: string; stepLabel: string; detail: string }
> = new Map(
  [
    {
      value: PickupLocation.FRONT_DOOR,
      label: "Front Door",
      stepLabel: "front door",
      detail: "from the front door",
    },
    {
      value: PickupLocation.BACK_DOOR,
      label: "Back Door",
      stepLabel: "back door",
      detail: "from the back door",
    },
    {
      value: PickupLocation.SIDE_DOOR,
      label: "Side Door",
      stepLabel: "side door",
      detail: "from the side door",
    },
    {
      value: PickupLocation.OTHER,
      label: "Other",
      stepLabel: "specified location",
      detail: "in another way",
    },
  ].map((option) => [option.value, option]),
);

export enum PickupPayer {
  MERCHANT = "merchant",
  CUSTOMER_DEDUCT = "customer-deduct",
  CUSTOMER_STRIPE = "customer-stripe",
}

export interface Pickup {
  email?: string | null;
  phone?: string | null;
  pickupDate: string;
  pickupLocation: {
    packageLocation: PickupLocation;
    // This is required if packageLocation is "OTHER"
    specialInstructions?: string | null;
  };
  textReminder: boolean;
  pickupPayer?: PickupPayer;
}

export interface Settlement {
  accepted: boolean;
  refund: number;
}

export interface DiscountCodeName {
  prefix?: string;
  suffix?: string;
}

export interface ReturnRequest {
  /** Information about the account requesting the return */
  requester: {
    /** Name of the account that sent the request */
    name?: string;
    /** Whether the account that sent the request is a customer (or a merchant) */
    isCustomer: boolean;
    /** The email associated with the creator of the return */
    email?: string;
  };
  /** Merchant charge to customer (non-negative); does not include shipping label */
  charge: string;
  /** The shipping fee total for all returns being created for return request */
  shippingFee: string;
  /** Shipping fee of an individual return */
  singleShippingFee: string;
  /** Whether the shipping fee is a flat rate or not */
  isFlatRate: boolean;
  /** Refund amount */
  refund: string;
  /** The total store credit, taking into account exchange fees */
  totalStoreCredit: string;
  /** The amount to collect from the customer if they fail to return for an instant exchange */
  returnCollectionHoldAmount: string | null;
  /** Return label, if there is one */
  label: ReturnLabel | null;
  /** Stripe payment token for an instant exchange */
  instantExchangePaymentToken: string | null;
  /** Items */
  items: ReturnItem[] | null;
  /** Items grouped into shipments */
  itemShipments: { merchantAddress: Address; items: ReturnItem[] }[] | null;
  /** Items from the advanced exchange cart */
  newItems: AdvancedExchangeItem[];
  /** Order ID */
  orderId: string;
  /** Status */
  status: ReturnRequestStatus;
  /** Provisioning */
  provision: ProvisionType;
  /** Address */
  shippingAddress: Address;
  /** Merchant Return Address */
  merchantAddress: Address | null; // null for rejected returns
  /** Array of stripe payment intents relating to this return. Each is stored for reference after first return is created and should not be charged. */
  orderPaymentIntents: any[];
  /** The total value of the new order including taxes and discounts */
  newOrderValue: string;
  /** The taxes associated with a new order - created via advanced exchange */
  newOrderTaxes: string;
  /** Manual price adjustment set by merchant */
  newOrderPriceAdjustment?: {
    type: "percentage" | "amount";
    value: number;
  };
  /** The cart discount allocations from advanced exchange */
  discountAllocations: DiscountAllocation[];
  /** Whether or not the label price was taken from customers refund or store credit values */
  labelDeductedFromCredit: boolean;
  /** The idempotency key used to create the return */
  idempotencyKey: string;
  /* The kind of return to create
  		"return" | "claim" */
  returnType: ReturnType;
  /* The address to send any new items to (applies only to exchange orders)
      If undefined, this will default to the address set in shippingAddress */
  newOrderAddress?: Address;
  /** The user ID from Astra, only used for instant refunds */
  astraUserId?: string;
  /* Details about package pickup */
  pickup?: Pickup;
  /** Whether return will be dropped of in-store, this is not the same as POS return */
  inStoreReturn: boolean;
  /** Details about refund settlement */
  settlement?: Settlement;
  /* Whether the return is eligible for pickup */
  eligibleForPickup?: boolean;
  /* Pickup fee advertised to the customer */
  pickupFee?: number;
  /** Whether to offer free shipping for the new order */
  freeNewOrderShipping?: boolean;
  /** Name of the shipping line for the exchange order */
  exchangeShippingName?: string;
  /** The discount code name */
  discountCodeName?: DiscountCodeName;

  collectUpsellFee?: boolean;
  /** The id of ab test in the return flow */
  testId?: string;
  /** The id of the treatment in the ab test */
  treatmentId?: string;
  /** The ids of the multi-order return ids to associate with the return. Will override orderId if both are present */
  orderIds?: string[];
  /** The total fee for repairs */
  repairFee?: number;
  /** Whether return is currently happening at physical POS */
  isPosReturn: boolean;
  /** Currency of the order / return */
  currency?: Currency | null;
  /** The shoppers local currency*/
  presentmentCurrency?: Currency | null;
  /** Whether they are using HappyReturns */
  happyReturnsData?: {
    items: unknown;
    retailerId?: string;
  };
}

export enum ReturnRequestStatus {
  PENDING = "pending",
  REJECTED = "rejected",
}

export const returnRequestStatus: JsonFormat<ReturnRequestStatus> = <
  JsonFormat<ReturnRequestStatus>
>stringJsonFormat;

export interface LabelResult {
  /** URL for postage label */
  postageUrl: URL;
  /** URL for QR code */
  qrCodeUrl: URL;
  /** The shipment carrier */
  carrier: string;
}

export const labelResultJson: JsonFormat<LabelResult> = objectJsonFormat(
  {
    postageUrl: urlJsonFormat,
    qrCodeUrl: urlJsonFormat,
    carrier: stringJsonFormat,
  },
  {},
);

export interface DraftOrder {
  url: URL;
}

export const draftOrderJsonFormat: JsonFormat<DraftOrder> = objectJsonFormat(
  { url: urlJsonFormat },
  {},
);

export interface ReturnResult {
  /** Draft order, if necessary */
  draftOrder: DraftOrder | null;
  /** Shipping label */
  label: LabelResult | null;
  creditType: StoreCreditType;
  returnId: String;
}

export interface AdvancedExchangeItem {
  /** Variant ID */
  variantId: string;
  /** Product title */
  title: string;
  /** Variant title */
  variantTitle: string;
  /** The price of the variant */
  price: string;
  /** The total value of the item. */
  itemValue?: string;
  /** The quantity of the variant */
  quantity: number;
  /** The variant image urls */
  images: string[];
  /** The currency code */
  currencyCode: string;
  /** Custom attributes on the line item that we want to carry through to the order */
  attributes?: { key: string; value?: string }[];
  /** The total tax amount of the new item */
  tax?: string;
}

export interface ExchangeGroupItem {
  /** Product ID */
  productId: string;
  /** Variant ID */
  variantId: string;
  /** Product title */
  title: string;
  /** Variant title */
  variantTitle: string;
  /** The variant image urls */
  imageSrc: string;
  /** The price of the variant */
  price?: string;
}

export interface ReturnItem {
  /** ID used to group return items into shipments */
  shipmentGroupID?: string;
  /** Variant exchange, if there is one */
  exchange: ReturnExchange | null;
  /** Input answers */
  inputAnswers: InputAnswer[];
  /** Requires manual review */
  isManualReview: boolean;
  /** Reason it was set to manual review */
  manualReviewReason?: string;
  /** Flagged to prevent automatic processing until unflagged */
  isFlagged: boolean;
  /** Reason it was flagged */
  flaggedReason?: string;
  /** Multiple choice answers */
  multipleChoiceAnswers: {
    answer: string;
    questionText: string;
  }[];
  /** ?? */
  variantId: string;
  /** Reason */
  reason: string | null;
  /** Refund, if there is one */
  refund: boolean;
  /** Is a repair */
  repair?: boolean;
  /** Whether item should be shipped (i.e. not green return or in-store return) */
  ship: boolean;
  /** The added bonus or fee of the item being returned, if there is one */
  priceAdjustment: string | null;
  /** The price of the item before adjustment */
  price: string | null;
  /** The tax on the item */
  tax?: string;
  /** The reason for rejecting the return, if rejected */
  rejectMessage?: string;
  productName: {
    title: string;
    variantTitle: string;
  };
  lineItemId: string;
  /** The bundle ID, if it exists */
  bundleId?: number;
  /** If the item being returned is part of a bundle that was unpackaged */
  isUnbundled?: boolean;
  /** The item is unbundled but we allow returns on exchange orders */
  allowFutureReturns?: boolean;
  /** Name of the shipping line for outgoing shipping (for exchange orders) */
  exchangeShippingName?: string;
  /** The id of the order associated with the item */
  orderId: string;
}

export enum InputPartType {
  IMAGES = "images",
  TEXTAREA = "text_area",
  VIDEOS = "videos",
}

export const inputPartTypeJsonFormat: JsonFormat<InputPartType> =
  stringJsonFormat as JsonFormat<InputPartType>;

export namespace Step {
  export const CONDITION = Symbol("condition");
  export const INPUT = Symbol("input");
  export const MANUAL_REVIEW = Symbol("manual_review");
  export const FLAG = Symbol("flag");
  export const EXCHANGE_SHIPPING_NAME = Symbol("exchange_shipping_name");
  export const DISCOUNT_CODE_NAME = Symbol("discount_code_name");
  export const OVERRIDE_ADJUSTMENT = Symbol("override_adjustment");
  export const OVERRIDE_GREEN_RETURN = Symbol("override_green_return");
  export const MULTIPLE_CHOICE = Symbol("multiple_choice");
  export const REJECT = Symbol("reject");
  export const RETURN = Symbol("return");
  export const SUBMIT_CLAIM = Symbol("submit_claim");
  export const SUBMIT_REPAIR = Symbol("submit_repair");
  export const SUBMIT_WARRANTY = Symbol("submit_warranty");
  export const SUBMIT_WARRANTY_REGISTRATION = Symbol(
    "submit_warranty_registration",
  );
  export const BLOCK = Symbol("block");
  export const CHAT_MULTIPLE_CHOICE = Symbol("chat_multiple_choice");
  export const CLOSE_CHAT = Symbol("close_chat");
  export const CUSTOMER_RESPONSE = Symbol("customer_response");
  export const SEND_MESSAGE = Symbol("send_message");
  export const ASSIGN_TO_AGENT = Symbol("assign_to_agent");
  export const REENTRY = Symbol("reentry");
  export const TRIGGER = Symbol("trigger");
  export const WAIT = Symbol("wait");
  export const SEND_EMAIL = Symbol("send_email");
  export const SEND_SMS = Symbol("send_sms");
  export const SEE_ORDERS = Symbol("see_orders");
  export const ACTION = Symbol("action");
  export const MULTIPLE_ACTIONS = Symbol("multiple_actions");
  export const MULTIPLE_CONDITIONS = Symbol("multiple_conditions");
  export const DISCOUNT = Symbol("discount");
  export const FINAL_SALE_RETURN = Symbol("final_sale_return");
  export const DO_NOTHING = Symbol("do_nothing");
  export const AB_TEST = Symbol("ab_test");

  /**
   * Conditional
   */
  export interface Condition {
    type: typeof CONDITION;
    customTitle?: string;
    /** Expression */
    condition: Condition_;
    /** Next step if true */
    nextTrue?: StepId;
    /** Next step if false */
    nextFalse?: StepId;
  }

  /**
   * Generic action used by rules
   */
  export interface Action {
    type: typeof ACTION;
    customTitle?: string;
    /** Expression */
    action: Action_;
  }

  export const conditionJsonFormat: JsonFormat<Condition> = objectJsonFormat(
    {
      type: symbolJsonFormat(CONDITION),
      condition: conditionJsonFormat_,
    },
    {
      customTitle: stringJsonFormat,
      nextTrue: bijectionRef(() => stepIdJsonFormat),
      nextFalse: bijectionRef(() => stepIdJsonFormat),
    },
  );

  export const actionJsonFormat: JsonFormat<Action> = objectJsonFormat(
    {
      type: symbolJsonFormat(ACTION),
      action: actionJsonFormat_,
    },
    {
      customTitle: stringJsonFormat,
    },
  );

  export interface InputPart {
    // The main question for the input
    message: string;
    // Additional description for guiding the user through the question
    description: string;
    // An example image to show the user when asking for image upload
    exampleImage?: URL;
    // Whether it is required or not, not sure what "none" should be, maybe we could make this a boolean named required?
    validation: InputValidation;
    type: InputPartType;
  }

  export const inputPartJson: JsonFormat<InputPart> = objectJsonFormat(
    {
      message: stringJsonFormat,
      description: stringJsonFormat,
      validation: inputValidationJson,
      type: inputPartTypeJsonFormat,
    },
    {
      exampleImage: urlJsonFormat,
    },
  );

  /**
   * Customer input
   */
  export interface Input {
    type: typeof INPUT;
    customTitle?: string;
    /** Parts */
    parts: InputPart[];
    /** Next step */
    next: StepId;
  }

  export const inputJsonFormat: JsonFormat<Input> = objectJsonFormat(
    {
      type: symbolJsonFormat(INPUT),
      parts: arrayJsonFormat(inputPartJson),
      next: bijectionRef(() => stepIdJsonFormat),
    },
    {
      customTitle: stringJsonFormat,
    },
  );

  /**
   * Discount
   */
  export interface Discount {
    type: typeof DISCOUNT;
    enabled: boolean;
    discountType: DiscountType;
    discountValueType: DiscountValueType;
    discountPercentageValue: number;
    discountAmountValue: number;
    freeShippingCountryCodes: string[];
    discountProductType?: DiscountProductType;
    discountProducts?: DiscountProduct[];
    discountCollections?: DiscountCollection[];
    discountCollectionsSelectionType?: DiscountCollectionsSelectionType;
    minimumRequirement: {
      type: DiscountMinRequirementType;
      minimum: number;
    };
    maximumShippingPrice: number;
    combinesWith: {
      orderDiscounts: boolean;
      productDiscounts: boolean;
      shippingDiscount: boolean;
    };
    expirationType: ExpirationType;
    expirationDays?: number;
  }

  export const discountProductsJsonFormat: JsonFormat<DiscountProduct> =
    objectJsonFormat({}, { id: stringJsonFormat, title: stringJsonFormat });

  export const discountCollectionsJsonFormat: JsonFormat<DiscountCollection> =
    objectJsonFormat({}, { id: stringJsonFormat, name: stringJsonFormat });

  export const discountJsonFormat: JsonFormat<Discount> = objectJsonFormat(
    {
      type: symbolJsonFormat(DISCOUNT),
      enabled: booleanJsonFormat,
      discountType: stringEnumJsonFormat(DiscountType),
      discountValueType: stringEnumJsonFormat(DiscountValueType),
      discountPercentageValue: numberJsonFormat,
      discountAmountValue: numberJsonFormat,
      freeShippingCountryCodes: arrayJsonFormat(stringJsonFormat),
      minimumRequirement: objectJsonFormat<{
        type: DiscountMinRequirementType;
        minimum: number;
      }>(
        {
          type: stringEnumJsonFormat(DiscountMinRequirementType),
          minimum: numberJsonFormat,
        },
        {},
      ),
      maximumShippingPrice: numberJsonFormat,
      expirationType: stringEnumJsonFormat(ExpirationType),
      combinesWith: objectJsonFormat<{
        orderDiscounts: boolean;
        productDiscounts: boolean;
        shippingDiscount: boolean;
      }>(
        {
          orderDiscounts: booleanJsonFormat,
          productDiscounts: booleanJsonFormat,
          shippingDiscount: booleanJsonFormat,
        },
        {},
      ),
    },
    {
      expirationDays: numberJsonFormat,
      discountProductType: stringEnumJsonFormat(DiscountProductType),
      discountProducts: arrayJsonFormat(discountProductsJsonFormat),
      discountCollections: arrayJsonFormat(discountCollectionsJsonFormat),
      discountCollectionsSelectionType: stringEnumJsonFormat(
        DiscountCollectionsSelectionType,
      ),
    },
  );

  export const doNothingJsonFormat: JsonFormat<Step.DoNothing> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(Step.DO_NOTHING),
      },
      {},
    );

  /**
   * Mark for manual review
   */
  export interface ManualReview {
    type: typeof MANUAL_REVIEW;
    customTitle?: string;
    /** Next step */
    next?: StepId;
    reason?: string;
  }

  export const manualReviewJsonFormat: JsonFormat<ManualReview> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(MANUAL_REVIEW),
      },
      {
        customTitle: stringJsonFormat,
        next: bijectionRef(() => stepIdJsonFormat),
        reason: stringJsonFormat,
      },
    );

  /**
   * Flag
   */
  export interface Flag {
    type: typeof FLAG;
    customTitle?: string;
    /** Next step */
    next?: StepId;
    reason?: string;
  }

  export const flagJsonFormat: JsonFormat<Flag> = objectJsonFormat(
    {
      type: symbolJsonFormat(FLAG),
    },
    {
      customTitle: stringJsonFormat,
      next: bijectionRef(() => stepIdJsonFormat),
      reason: stringJsonFormat,
    },
  );

  /**
   * Exchange shipping name
   */
  export interface ExchangeShippingName {
    type: typeof EXCHANGE_SHIPPING_NAME;
    customTitle?: string;
    next?: StepId;
    shippingName: string;
  }

  export const exchangeShippingNameJsonFormat: JsonFormat<ExchangeShippingName> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(EXCHANGE_SHIPPING_NAME),
        shippingName: stringJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
        next: bijectionRef(() => stepIdJsonFormat),
      },
    );

  /**
   * Discount code name
   */
  export interface DiscountCodeName {
    type: typeof DISCOUNT_CODE_NAME;
    customTitle?: string;
    prefix?: string;
    suffix?: string;
    next?: StepId;
  }

  export const discountCodeNameJsonFormat: JsonFormat<DiscountCodeName> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(DISCOUNT_CODE_NAME),
      },
      {
        customTitle: stringJsonFormat,
        prefix: stringJsonFormat,
        suffix: stringJsonFormat,
        next: bijectionRef(() => stepIdJsonFormat),
      },
    );

  /**
   * Override adjustment
   */
  export interface OverrideAdjustment {
    type: typeof OVERRIDE_ADJUSTMENT;
    customTitle?: string;
    /** Next step */
    next?: StepId;
    /** Adjustment */
    adjustment: Adjustment;
  }

  export const overrideAdjustmentJsonFormat: JsonFormat<OverrideAdjustment> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(OVERRIDE_ADJUSTMENT),
        adjustment: adjustmentJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
        next: bijectionRef(() => stepIdJsonFormat),
      },
    );

  export interface OverrideGreenReturn {
    type: typeof OVERRIDE_GREEN_RETURN;
    customTitle?: string;
    next?: StepId;
    greenReturnOverride: boolean;
  }

  export const overrideGreenReturnJsonFormat: JsonFormat<OverrideGreenReturn> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(OVERRIDE_GREEN_RETURN),
        greenReturnOverride: booleanJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
        next: bijectionRef(() => stepIdJsonFormat),
      },
    );

  /**
   * Customer multiple-choice question
   */
  export interface MultipleChoice {
    type: typeof MULTIPLE_CHOICE;
    customTitle?: string;
    /** Message */
    message: string;
    /** Options */
    options: MultipleChoiceOption[];
    /** Set the return reason from the answer to this question. */
    reason: boolean;
    /** Whether to shuffle the options */
    shuffle?: boolean;
  }

  export const multipleChoiceJsonFormat: JsonFormat<MultipleChoice> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(MULTIPLE_CHOICE),
        message: stringJsonFormat,
        options: arrayJsonFormat(multipleChoiceOptionJsonFormat),
        reason: booleanJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
        shuffle: booleanJsonFormat,
      },
    );

  /**
   * Chat multiple choice question
   */
  export interface ChatMultipleChoice {
    type: typeof CHAT_MULTIPLE_CHOICE;
    customTitle?: string;
    /** Message */
    message: string;
    messageHtml?: string;
    /** Options */
    options: ChatMultipleChoiceOption[];
  }

  export const chatMultipleChoiceJsonFormat: JsonFormat<ChatMultipleChoice> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(CHAT_MULTIPLE_CHOICE),
        message: stringJsonFormat,
        options: arrayJsonFormat(chatMultipleChoiceOptionJsonFormat),
      },
      {
        customTitle: stringJsonFormat,
        messageHtml: stringJsonFormat,
      },
    );

  /**
   * Rejection
   */
  export interface Reject {
    type: typeof REJECT;
    customTitle?: string;
    /** Message */
    message: string;
  }

  export const rejectJsonFormat: JsonFormat<Reject> = objectJsonFormat(
    { type: symbolJsonFormat(REJECT), message: stringJsonFormat },
    {
      customTitle: stringJsonFormat,
    },
  );

  /**
   * Return
   */
  export interface Return {
    type: typeof RETURN;
    customTitle?: string;
    /** Options */
    options: ReturnOption[];
    /** Whether to use a flat shipping rate or not */
    flatShipping?: boolean;
    /** The value of the flat shipping rate, if flat shipping is enabled */
    flatShippingRate?: string;
  }

  export const returnJsonFormat: JsonFormat<Return> = objectJsonFormat(
    {
      type: symbolJsonFormat(RETURN),
      options: arrayJsonFormat(returnOptionJsonFormat),
    },
    {
      customTitle: stringJsonFormat,
      flatShipping: booleanJsonFormat,
      flatShippingRate: stringJsonFormat,
    },
  );

  /**
   * Submit Claim
   */
  export interface SubmitClaim {
    type: typeof SUBMIT_CLAIM;
    customTitle?: string;
    option: ClaimOption;
  }

  export const submitClaimJsonFormat: JsonFormat<SubmitClaim> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(SUBMIT_CLAIM),
        option: claimOptionJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
      },
    );

  export interface WarrantyOption {
    /** Return method */
    method: ReturnOptionMethod;
    requireSendBack: boolean;
  }

  export const warrantyOptionJsonFormat: JsonFormat<WarrantyOption> =
    objectJsonFormat(
      {
        method: returnOptionMethodJsonFormat,
        requireSendBack: booleanJsonFormat,
      },
      {},
    );

  export interface SubmitWarranty {
    type: typeof SUBMIT_WARRANTY;
    customTitle?: string;
    option: WarrantyOption;
  }

  export const submitWarrantyJsonFormat: JsonFormat<SubmitWarranty> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(SUBMIT_WARRANTY),
        option: warrantyOptionJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
      },
    );

  export interface SubmitWarrantyRegistration {
    type: typeof SUBMIT_WARRANTY_REGISTRATION;
    customTitle?: string;
  }

  export const submitWarrantyRegistrationJsonFormat: JsonFormat<SubmitWarrantyRegistration> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(SUBMIT_WARRANTY_REGISTRATION),
      },
      {
        customTitle: stringJsonFormat,
      },
    );

  export interface SubmitRepair {
    type: typeof SUBMIT_REPAIR;
    customTitle?: string;
  }

  export const submitRepairJsonFormat: JsonFormat<SubmitRepair> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(SUBMIT_REPAIR),
      },
      {
        customTitle: stringJsonFormat,
      },
    );

  /**
   * Block
   */
  export interface Block {
    type: typeof BLOCK;
    customTitle?: string;
    message: string;
  }

  export const blockJsonFormat: JsonFormat<Block> = objectJsonFormat(
    {
      type: symbolJsonFormat(BLOCK),
      message: stringJsonFormat,
    },
    {
      customTitle: stringJsonFormat,
    },
  );

  /**
   * Send message
   */
  export interface SendMessage {
    type: typeof Step.SEND_MESSAGE;
    customTitle?: string;
    next: StepId;
    message: string;
    messageHtml?: string;
  }
  export const sendMessageJsonFormat: JsonFormat<SendMessage> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(Step.SEND_MESSAGE),
        next: bijectionRef(() => stepIdJsonFormat),
        message: stringJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
        messageHtml: stringJsonFormat,
      },
    );

  /**
   * Close chat
   */
  export interface CloseChat {
    type: typeof CLOSE_CHAT;
    customTitle?: string;
    message: string;
    messageHtml?: string;
  }

  export const closeChatJsonFormat: JsonFormat<CloseChat> = objectJsonFormat(
    {
      type: symbolJsonFormat(CLOSE_CHAT),
      message: stringJsonFormat,
    },
    {
      customTitle: stringJsonFormat,
      messageHtml: stringJsonFormat,
    },
  );

  /**
   * Reentry
   */
  export interface Reentry {
    type: typeof Step.REENTRY;
    customTitle?: string;
    message: string;
    options: MultipleChoiceOption[];
  }
  export const reentryJsonFormat: JsonFormat<Reentry> = objectJsonFormat(
    {
      type: symbolJsonFormat(Step.REENTRY),
      message: stringJsonFormat,
      options: arrayJsonFormat(multipleChoiceOptionJsonFormat),
    },
    {
      customTitle: stringJsonFormat,
    },
  );

  /**
   * Assign to agent
   */
  export interface AssignToAgent {
    type: typeof Step.ASSIGN_TO_AGENT;
    customTitle?: string;
    message: string;
    messageHtml?: string;
    emailPromptMessage: string;
    emailPromptMessageHtml?: string;
    followUpMessage: string;
    followUpMessageHtml?: string;
    channel: ConversationPlatform;
    emailToSendAs?: {
      email: string;
      name: string;
      integrationKind: string;
    };
  }

  export const assignToAgentJsonFormat: JsonFormat<AssignToAgent> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(Step.ASSIGN_TO_AGENT),
        message: stringJsonFormat,
        emailPromptMessage: stringJsonFormat,
        followUpMessage: stringJsonFormat,
        channel: conversationPlatformJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
        emailToSendAs: emailToSendAsJsonFormat,
        messageHtml: stringJsonFormat,
        emailPromptMessageHtml: stringJsonFormat,
        followUpMessageHtml: stringJsonFormat,
      },
    );

  /**
   * Customer response
   */
  export interface CustomerResponse {
    type: typeof Step.CUSTOMER_RESPONSE;
    customTitle?: string;
    next: StepId;
    message: string;
    messageHtml?: string;
    infoToCollect: InfoToCollect;
  }

  export const customerResponseJsonFormat: JsonFormat<CustomerResponse> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(Step.CUSTOMER_RESPONSE),
        next: bijectionRef(() => stepIdJsonFormat),
        message: stringJsonFormat,
        infoToCollect: infoToCollectJsonFormat,
      },
      {
        customTitle: stringJsonFormat,
        messageHtml: stringJsonFormat,
      },
    );

  /**
   * See orders
   */
  export interface SeeOrders {
    type: typeof Step.SEE_ORDERS;
    customTitle?: string;
    message: string;
    messageHtml?: string;
    next: StepId;
  }

  export const seeOrdersJsonFormat: JsonFormat<SeeOrders> = objectJsonFormat(
    {
      type: symbolJsonFormat(Step.SEE_ORDERS),
      message: stringJsonFormat,
      next: bijectionRef(() => stepIdJsonFormat),
    },
    {
      customTitle: stringJsonFormat,
      messageHtml: stringJsonFormat,
    },
  );

  export interface Trigger {
    type: typeof Step.TRIGGER;
    customTitle?: string;
    triggerType: TriggerType;
    next: StepId;
  }

  export const triggerJsonFormat: JsonFormat<Trigger> = objectJsonFormat(
    {
      type: symbolJsonFormat(Step.TRIGGER),
      triggerType: triggerTypeJsonFormat,
      next: bijectionRef(() => stepIdJsonFormat),
    },
    {
      customTitle: stringJsonFormat,
    },
  );

  export interface Wait {
    type: typeof Step.WAIT;
    customTitle?: string;
    numDays: number;
    next: StepId;
  }

  export const waitJsonFormat: JsonFormat<Wait> = objectJsonFormat(
    {
      type: symbolJsonFormat(Step.WAIT),
      numDays: numberJsonFormat,
      next: bijectionRef(() => stepIdJsonFormat),
    },
    {
      customTitle: stringJsonFormat,
    },
  );

  export interface SendEmail {
    type: typeof Step.SEND_EMAIL;
    emailId: string;
    emailName?: string;
    emailType?: string;
    customTitle?: string;
    next?: StepId;
  }

  export const sendEmailJsonFormat: JsonFormat<SendEmail> = objectJsonFormat(
    {
      type: symbolJsonFormat(Step.SEND_EMAIL),
      emailId: stringJsonFormat,
    },
    {
      customTitle: stringJsonFormat,
      emailName: stringJsonFormat,
      emailType: stringJsonFormat,
      next: bijectionRef(() => stepIdJsonFormat),
    },
  );

  export interface SendSms {
    type: typeof Step.SEND_SMS;
    smsId: string;
    smsName?: string;
    smsType?: string;
    customTitle?: string;
    next?: StepId;
  }

  export const sendSmsJsonFormat: JsonFormat<SendSms> = objectJsonFormat(
    {
      type: symbolJsonFormat(Step.SEND_SMS),
      smsId: stringJsonFormat,
    },
    {
      customTitle: stringJsonFormat,
      smsName: stringJsonFormat,
      smsType: stringJsonFormat,
      next: bijectionRef(() => stepIdJsonFormat),
    },
  );

  export const multipleActionsJsonFormat: JsonFormat<MultipleActions> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(Step.MULTIPLE_ACTIONS),
        actions: arrayJsonFormat(actionJsonFormat),
      },
      {
        next: bijectionRef(() => stepIdJsonFormat),
      },
    );

  export const multipleConditionsJsonFormat: JsonFormat<MultipleConditions> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(Step.MULTIPLE_CONDITIONS),
        conditions: arrayJsonFormat(conditionJsonFormat),
      },
      {
        nextTrue: bijectionRef(() => stepIdJsonFormat),
        nextFalse: bijectionRef(() => stepIdJsonFormat),
      },
    );

  /**
   * Multiple Actions in a single step
   */
  export interface MultipleActions {
    type: typeof MULTIPLE_ACTIONS;
    actions: Action[];
    next?: StepId;
  }

  /**
   * Multiple Actions in a single step
   */
  export interface MultipleConditions {
    type: typeof MULTIPLE_CONDITIONS;
    conditions: Condition[];
    nextTrue?: StepId;
    /** Next step if false */
    nextFalse?: StepId;
  }

  /**
   * Final sale return
   */
  export interface FinalSaleReturn {
    type: typeof FINAL_SALE_RETURN;
    /** Next step */
    next?: StepId;
  }

  /**
   * Do nothing
   */
  export interface DoNothing {
    type: typeof DO_NOTHING;
  }

  export const finalSaleJsonFormat: JsonFormat<FinalSaleReturn> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(FINAL_SALE_RETURN),
      },
      {
        next: bijectionRef(() => stepIdJsonFormat),
      },
    );

  /**
   * AB test
   */
  export interface ABTest {
    type: typeof AB_TEST;
    name: string;
    customTitle?: string;
    /** Options */
    treatments: ABTestTreatment[];
    /** Set the return reason from the answer to this question. */
  }

  export const ABTestJsonFormat: JsonFormat<ABTest> = objectJsonFormat(
    {
      type: symbolJsonFormat(AB_TEST),
      treatments: arrayJsonFormat(abTestTreatmentJsonFormat),
      name: stringJsonFormat,
    },
    {
      customTitle: stringJsonFormat,
    },
  );
}

export const stepJsonFormat: JsonFormat<Step | ChatFlowStep> =
  symbolUnionJsonFormat("type", "type", {
    [Step.CONDITION]: Step.conditionJsonFormat,
    [Step.INPUT]: Step.inputJsonFormat,
    [Step.MANUAL_REVIEW]: Step.manualReviewJsonFormat,
    [Step.FLAG]: Step.flagJsonFormat,
    [Step.EXCHANGE_SHIPPING_NAME]: Step.exchangeShippingNameJsonFormat,
    [Step.DISCOUNT_CODE_NAME]: Step.discountCodeNameJsonFormat,
    [Step.OVERRIDE_ADJUSTMENT]: Step.overrideAdjustmentJsonFormat,
    [Step.OVERRIDE_GREEN_RETURN]: Step.overrideGreenReturnJsonFormat,
    [Step.MULTIPLE_CHOICE]: Step.multipleChoiceJsonFormat,
    [Step.REJECT]: Step.rejectJsonFormat,
    [Step.RETURN]: Step.returnJsonFormat,
    [Step.SUBMIT_CLAIM]: Step.submitClaimJsonFormat,
    [Step.SUBMIT_REPAIR]: Step.submitRepairJsonFormat,
    [Step.SUBMIT_WARRANTY]: Step.submitWarrantyJsonFormat,
    [Step.SUBMIT_WARRANTY_REGISTRATION]:
      Step.submitWarrantyRegistrationJsonFormat,
    [Step.BLOCK]: Step.blockJsonFormat,
    [Step.CHAT_MULTIPLE_CHOICE]: Step.chatMultipleChoiceJsonFormat,
    [Step.CLOSE_CHAT]: Step.closeChatJsonFormat,
    [Step.CUSTOMER_RESPONSE]: Step.customerResponseJsonFormat,
    [Step.SEND_MESSAGE]: Step.sendMessageJsonFormat,
    [Step.ASSIGN_TO_AGENT]: Step.assignToAgentJsonFormat,
    [Step.REENTRY]: Step.reentryJsonFormat,
    [Step.TRIGGER]: Step.triggerJsonFormat,
    [Step.WAIT]: Step.waitJsonFormat,
    [Step.SEND_EMAIL]: Step.sendEmailJsonFormat,
    [Step.SEND_SMS]: Step.sendSmsJsonFormat,
    [Step.SEE_ORDERS]: Step.seeOrdersJsonFormat,
    [Step.ACTION]: Step.actionJsonFormat,
    [Step.MULTIPLE_ACTIONS]: Step.multipleActionsJsonFormat,
    [Step.MULTIPLE_CONDITIONS]: Step.multipleConditionsJsonFormat,
    [Step.DISCOUNT]: Step.discountJsonFormat,
    [Step.DO_NOTHING]: Step.doNothingJsonFormat,
    [Step.AB_TEST]: Step.ABTestJsonFormat,
  });

export enum BundleValueType {
  /** sku */
  SKU = "sku",
  /** variant ID */
  VARIANT_ID = "variantId",
  /** bundle ID */
  BUNDLE_ID = "bundleId",
}

export enum BundleType {
  MERGE = "merge",
  EXPAND = "expand",
}

export interface ValueTransform {
  /** A regex pattern for what to replace */
  search: string;
  /** What to replace the searched pattern with */
  replace: string;
}

export interface BundleRules {
  /** The key that will match the line item property name */
  key: string;
  /** The type of the line item property value */
  valueType: BundleValueType;
  /** Whether the bundle info is stored on a property in the order */
  isProperty: boolean;
  /** Whether is a merge or expand bundle */
  type: BundleType;
  /* The delimiter used to separate the values (if the property
      value contains multiple values) */
  valueDelimiter: string;
  /** A Regex pattern to parse the property value */
  valueParser: string;
  /** A regex pattern to parse the quantity */
  quantityParser: string;
  /** How we should transform the value after we extract it */
  valueTransforms: ValueTransform[];
}

export interface ExpandBundleRules extends BundleRules {
  bundleType: typeof BundleType.EXPAND;
  variantExchangeOnly: boolean;
}

export interface MergeBundleRules extends BundleRules {
  bundleType: typeof BundleType.MERGE;
}

/**
 * Different than bundle rules. Bundle rules are used in the backend to automatically detect bundles based on properties
 * on the order/line items. Bundles (defined by this interface) are set up manually in the UI by the merchant
 */
export interface Bundle {
  /* The name the merchant gives the bundle */
  name: string;
  parentVariantId: string;
  parts: {
    variantId: string;
    returnable: boolean;
  }[];
  allowedTypes: string[];
}

// FIXME Actually type this
export type Item = any;

export type BaseReturnableItem = {
  /** The unique bundle ID */
  id: number;
  /** The bundle rules (undefined if not a bundle) */
  rules?: BundleRules;
  /** The value associated with the bundle (undefined if not a bundle) */
  value?: string;
  /** The items in the bundle (contains one item if not a bundle) */
  items: Item[];
  /** The item in the bundle who's FRE we will follow */
  lineItemSource: Item;
  /** The bundle's original state before going through the return flow */
  initialItem?: ReturnableItem;
  /* If false, we are taking multiple line items and putting them together into one bundle
      If true, we are taking one line item and splitting it into multiple returnable items */
  isExpanded: boolean;
};

export interface MergedBundle extends BaseReturnableItem {
  /** The index of the freSourceItem in the items array */
  lineItemSourceIndex: number;
  /** We are taking multiple line items and putting them together into on bundle */
  isExpanded: false;
}

export interface ExpandedBundle extends BaseReturnableItem {
  /** The original line item before unbundling */
  bundledItem: Item;
  /** We are taking one line item and splitting it into multiple returnable items */
  isExpanded: true;
  /* Whether we are returning parts of the bundle (for variant exchanges) or the
      whole bundle (for store credit, refunds, or Shop Now) */
  returningParts: boolean;
  /** Parts to return if returning parts of the bundle */
  partsToReturn: any[];
}

export type ReturnableItem = MergedBundle | ExpandedBundle;

export type ShopifyProperty = { name: string; value: string };

/** The default index to use for the freSourceItemIndex */
export const LINE_ITEM_SOURCE_INDEX = 0;

/**
 * Step ID
 */
export type StepId = number;

export const stepIdJsonFormat: JsonFormat<StepId> = numberJsonFormat;

export function getTreatment(
  treatments: ABTestTreatment[],
  treatmentHash: string,
): ABTestTreatment | undefined {
  const hashPercentage = parseInt(treatmentHash, 16) % 100;

  const cumulativePercentages = treatments.map((bucket, index) => {
    return treatments
      .slice(0, index + 1)
      .reduce((sum, b) => sum + b.percentage, 0);
  });

  for (let i = 0; i < cumulativePercentages.length; i++) {
    if (hashPercentage < cumulativePercentages[i]) {
      return treatments[i];
    }
  }
  return undefined;
}
