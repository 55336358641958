import { USA_PROVINCES_BY_CODE } from "@redotech/locale/provinces";
import {
  getCollectionsForProduct,
  getLocations,
  getProduct,
  getProductsBySkus,
  getProductsByVariantIds,
  getSettings,
} from "@redotech/redo-customer-portal-app/api";
import type { ReturnAppSettings } from "@redotech/redo-customer-portal-app/contexts/settings";
import { ReturnTypeName } from "@redotech/redo-model/draft-return";
import type {
  Order,
  OrderWithReturnableItems,
} from "@redotech/redo-model/order";
import { LineItem } from "@redotech/redo-model/order";
import { ReturnType as Type, isDamaged } from "@redotech/redo-model/return";
import {
  Adjustment,
  AdvancedExchangeItem,
  Bundle,
  BundleRules,
  BundleType,
  BundleValueType,
  ExpandBundleRules,
  ExpandedBundle,
  LINE_ITEM_SOURCE_INDEX,
  MergedBundle,
  ProvisionType,
  ReturnOption,
  ReturnableItem,
  ShopifyProperty,
} from "@redotech/redo-model/return-flow";
import {
  DiscountName,
  PriceOption,
  SkuOption,
} from "@redotech/redo-model/return-flow/condition";
import { ReturnOptionMethod } from "@redotech/redo-model/return-flow/return-option";
import {
  ReturnTotals,
  ReturnTotalsCalculator,
} from "@redotech/redo-model/return-totals-calculator";
import { Channel, SalesChannels } from "@redotech/redo-model/sales-channels";
import {
  Address,
  DiscountDistributionMethod,
  ExchangeGroup,
  LocationCondition,
  ShippingLocation,
} from "@redotech/redo-model/team";
import { ProductVariant } from "@redotech/shopify-client/admin.graphql";
import * as upperCase from "lodash/upperCase";
import type { StorefrontCart } from "../contexts/StorefrontCartContext";

export const REDO_APP_ID = "3426665";

export const isDevEnvironment = () => process.env.NODE_ENV === "development";

export async function setFavicon() {
  const response = await getSettings();
  const settings = response?.data;
  // https://app.clickup.com/t/868680b85
  const url =
    settings?.settings.brandKit?.images.faviconUrl ||
    settings?.settings.brandKit?.images.logoUrl;
  if (url) {
    const link = document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;
    document.head.appendChild(link);
  }
}

export function optional<T, V>(
  value: T | null | undefined,
  fn: (value: T) => V,
): V | null {
  if (value === null || value === undefined) {
    return null;
  }

  return fn(value);
}

export function calculateReturnTotals(
  itemsReadyForReturn: ReturnableItem[],
  currentOrder: Order,
  settings: ReturnAppSettings,
  shopifyStorefrontCart: StorefrontCart | undefined,
): ReturnTotals {
  const products: ReturnType<typeof apiOutput>[] = [];
  for (const item of getProductsFromReturnableItems(itemsReadyForReturn)) {
    const adjustment = item.selected_return_option?.adjustment || {
      flat: 0,
      proportion: 1,
    };
    const priceAdjustment = getAdjustmentValue(
      adjustment,
      item.discount_price,
    ).toString();
    products.push(apiOutput(item, priceAdjustment, currentOrder, settings));
  }
  const returnTotalsCalculator = new ReturnTotalsCalculator({
    return_: {
      provision: ProvisionType.PROCESSED,
      products,
      new_order_taxes:
        shopifyStorefrontCart?.cost?.totalTaxAmount?.amount ?? "0.00",
      totals: {
        fee: 0,
      },
      discount_allocations: shopifyStorefrontCart?.discountAllocations || [],
      advancedExchangeItems: getAdvancedExchangeItemsFromShopifyCart(
        shopifyStorefrontCart,
        [],
      ),
    },
    order: currentOrder,
    team: { settings },
  });
  const returnTotals: ReturnTotals =
    returnTotalsCalculator.getTotalsForAllProducts();

  return returnTotals;
}

export function getAdjustmentValue(
  adjustment: Adjustment,
  discountPrice: string,
): number {
  const adjustmentValue =
    parseFloat(discountPrice) * (adjustment.proportion - 1.0) + adjustment.flat;

  return adjustmentValue;
}

export function getShowAdjustment(adjustment: Adjustment): boolean {
  return adjustment.flat !== 0 || adjustment.proportion !== 1;
}

/** @returns Whether the adjustment was actually applied earlier (sometimes adjustments are ignored for variant exchanges, etc) */
export function isAdjustmentApplied(
  valueBefore: string | number,
  valueAfter: string | number,
): boolean {
  return Number(valueBefore) !== Number(valueAfter);
}
/** Converts item lines from a Shopify cart into AdvancedExchangeItems for our systems */
export function getAdvancedExchangeItemsFromShopifyCart(
  shopifyStorefrontCart: StorefrontCart | undefined,
  taxes: { variantId: string; tax: number | string }[],
): AdvancedExchangeItem[] {
  const newItems: AdvancedExchangeItem[] = [];
  const cartDiscountPercentage =
    parseFloat(
      shopifyStorefrontCart?.cost?.checkoutChargeAmount?.amount ?? "0.00",
    ) /
    parseFloat(shopifyStorefrontCart?.cost?.subtotalAmount?.amount ?? "0.00"); // FIXME Division by zero just waiting to happen
  if (shopifyStorefrontCart?.lines?.edges?.length) {
    let priceOfItems = 0.0;
    for (const cartItem of shopifyStorefrontCart.lines.edges) {
      const graphqlVariantId = cartItem.node.merchandise.id;

      // Parse the variant id from the graphql id
      const variantId = graphqlVariantId.substring(
        graphqlVariantId.indexOf("ProductVariant/") + "ProductVariant/".length,
      );
      priceOfItems += parseFloat(cartItem.node.cost.totalAmount.amount);
      const tax = taxes.find((tax) => tax.variantId === variantId);
      const newItem: AdvancedExchangeItem = {
        variantId: variantId,
        title: cartItem.node.merchandise.product.title,
        variantTitle:
          cartItem?.node?.merchandise?.title !== "Default Title"
            ? cartItem.node.merchandise.title
            : "",
        // Storefront cart uses the total value for a line item, we will split the price by each product
        price: (
          parseFloat(cartItem.node.cost.totalAmount.amount) /
          cartItem.node.quantity
        ).toFixed(2),
        itemValue: (
          (parseFloat(cartItem.node.cost.totalAmount.amount) /
            cartItem.node.quantity) *
          cartDiscountPercentage
        ).toFixed(2),
        images: [cartItem.node.merchandise.image?.originalSrc],
        quantity: 1,
        currencyCode: cartItem.node.cost.totalAmount.currencyCode,
        attributes: cartItem.node.attributes || [],
        tax: (+tax?.tax || 0).toFixed(2),
      };
      for (let i = 0; i < cartItem.node.quantity; i++) {
        newItems.push(newItem);
      }
    }
  }

  return newItems;
}

// TODO Lots of similarities with type Product in ReturnTotalsCalculator - consolidate
export interface APIOutputProduct {
  multipleChoiceAnswers: MultipleChoiceAnswer[];
  order_id: string;
  type: string;
  strategy: string;
  line_item_id: string;
  quantity: number;
  tags: string[];
  returnReason: string;
  price_adjustment: string;
  productId: string;
  price: string;
  sku: string;
  new_item: unknown;
  exchangeGroupItem?: unknown;
  isUnbundled: boolean;
}

export function apiOutput(
  item: {
    multipleChoiceAnswers: MultipleChoiceAnswer[];
    green_return: boolean;
    selected_return_option: ReturnOption;
    id: string;
    quantity: number;
    tags: string[];
    returnReason: string;
    product_id: string;
    discount_price: string;
    sku: string;
    new_item: unknown;
    exchangeGroupItem: unknown;
    isUnbundled: boolean;
  },
  priceAdjustment: string,
  order: { _id: string },
  settings: ReturnAppSettings,
): APIOutputProduct {
  return {
    multipleChoiceAnswers: item.multipleChoiceAnswers,
    order_id: order._id,
    type: item.green_return
      ? "green_return"
      : item.selected_return_option?.method.type ===
            ReturnOptionMethod.REFUND ||
          item.selected_return_option?.method.type ===
            ReturnOptionMethod.STORE_CREDIT
        ? "return"
        : "exchange",
    strategy: item.green_return
      ? "green_return"
      : item.selected_return_option?.method.type === ReturnOptionMethod.EXCHANGE
        ? settings.exchanges.excessExchangeValue
        : item.selected_return_option?.method.type === ReturnOptionMethod.REFUND
          ? "refund"
          : "store_credit",
    line_item_id: item.id,
    quantity: item.quantity,
    tags: item.tags,
    returnReason: item.returnReason,
    price_adjustment: priceAdjustment,
    productId: item.product_id,
    price: item.discount_price,
    sku: item.sku,
    new_item: item.new_item,
    exchangeGroupItem: item.exchangeGroupItem,
    isUnbundled: item.isUnbundled,
  };
}

export const extractPriceFromProperties = (lineItem, order) => {
  let originItemPrice;
  let originItemDiscountPrice;

  if (!order?.isExchangeOrder) {
    return null;
  }
  const originItem =
    order?.originItems?.find(
      (item) =>
        item.applicableShopifyOrder === order.shopify.id &&
        item.newVariantId === lineItem.variant_id,
    ) ||
    order?.originItems?.find(
      (item) => item.newVariantId === lineItem.variant_id,
    );
  if (originItem) {
    originItemPrice = originItem.preDiscountPrice || originItem.unitPrice;
    originItemDiscountPrice = originItem.unitPrice;
  }
  if (!originItemPrice) {
    const originItem = lineItem.properties?.find(
      ({ name }) => name === "_redo_origin_item",
    );
    originItemPrice =
      originItem?.preDiscountPrice || originItem?.unitPrice || null;
    originItemDiscountPrice = originItem?.unitPrice || null;
  }

  return originItemPrice
    ? {
        original_price: originItemPrice,
        discount_price: originItemDiscountPrice,
      }
    : null;
};

export const hasRedoDiscountCode = (data) => {
  return data.shopify.discount_applications?.some((discount) =>
    discount.code?.includes("REDO"),
  );
};

export const getItemData = (
  item,
  distributionMethod,
  order: OrderWithReturnableItems,
  product = undefined,
) => {
  const extractedPrice = extractPriceFromProperties(item, order);
  // Won't make api request if it already has for that product.
  // let product = products.find((product) => product.id === item.product_id);
  return {
    ...item,
    ...{
      processed_at: new Date(order.shopify.processed_at),
      shipping_fees: {
        store_credit: 0,
        exchange: 0,
        refund: 0,
      },
      coverage: {
        exchange: order.protected && order.coverage.exchange,
        refund: order.protected && order.coverage.refund,
        store_credit: order.protected && order.coverage.storeCredit,
      },
      original_price: item.price,
      discount_price: ReturnTotalsCalculator.getDiscountPrice(
        item,
        order,
        distributionMethod,
      ),
      green_return_selected: false,
      green_return_type: "discount", //Default value
      return: null, // This is to define what we do with the product.
      product: product,
      fixed_strategies: [],
      is_exchange_order: order.isExchangeOrder,
      is_defective: null,
      is_wrong_item: null,
      isManualReview: false,
      isFlagged: false,
      // We use this flag to determine if we should split this single item in a new
      // return. This is used for returns that require approval by the merchant.
      split: false,
      inputAnswers: [], // should be type InputAnswer
      readyToReturn: false, // Set this to true once user has completed flow for item.
      selected_return_option: null,
      new_item: null,
      returnReason: undefined,
      initialLineItem: undefined,
      ...extractedPrice,
    },
  };
};

export function getDistributionMethod(
  settings: ReturnAppSettings,
): DiscountDistributionMethod {
  return (
    settings.discountDistributionMethod || DiscountDistributionMethod.LINE_ITEM
  );
}

// This alters the whole order coming in
export async function makeOrder(
  data,
  settings: ReturnAppSettings,
): Promise<OrderWithReturnableItems> {
  const tempData = data;
  const lineItems = data.shopify.line_items.filter(
    (item) => !ReturnTotalsCalculator.isRedoItem(item),
  );
  const productIds: (number | null)[] = [];
  lineItems.forEach((item) => {
    if (!productIds.includes(item.product_id)) {
      productIds.push(item.product_id);
    }
  });

  const products = [];
  if (data.provider === "shopify") {
    await Promise.all(
      productIds.map(async (productId) => {
        products.push((await getProduct(productId?.toString())).data);
      }),
    );
  }

  const distributionMethod = getDistributionMethod(settings);

  const modifiedLineItems = lineItems.map((item) => {
    // const extractedPrice = extractPriceFromProperties(item, data);
    // Won't make api request if it already has for that product.
    const product = products.find((product) => product.id === item.product_id);

    return getItemData(item, distributionMethod, data, product);
  });
  tempData.line_items = modifiedLineItems;

  if (settings?.exchanges?.blockDiscountsOnExchanges && tempData.shopify) {
    tempData.shopify.discount_codes = [];
  }
  return tempData;
}

const transformValue = (parsedValue: string, bundleRules: BundleRules) => {
  let transformedValue = parsedValue;
  for (const valueTransform of bundleRules.valueTransforms) {
    transformedValue = transformedValue.replace(
      new RegExp(valueTransform.search, "g"),
      valueTransform.replace,
    );
  }

  return transformedValue;
};

const getQuantity = (parsedValue: string, bundleRules: BundleRules) => {
  const quantity = parsedValue.match(new RegExp(bundleRules.quantityParser));
  if (!quantity) {
    return 1;
  }
  return parseInt(quantity.pop() ?? "1");
};

const parseValue = (value: string, bundleRules: BundleRules) => {
  const parsedValues: string[] = value.match(
    new RegExp(bundleRules.valueParser, "g"),
  );
  const valuesWithQuantity = parsedValues.flatMap((parsedValue) => {
    const quantity: number = getQuantity(parsedValue, bundleRules);
    return Array<string>(quantity).fill(parsedValue);
  });
  const transformedValues = valuesWithQuantity.map((value) =>
    transformValue(value, bundleRules),
  );

  return transformedValues.join(bundleRules.valueDelimiter);
};

export const parseGid = (gid: string) => {
  return /[^/]*$/.exec(gid)![0];
};

const unbundleItems = (item: any, bundle: ExpandedBundle) => {
  const bundles: MergedBundle[] = [];

  const totalPrice = bundle.items.reduce(
    (acc, item) => (acc += +item.price),
    0,
  );
  for (const part of bundle.items) {
    part.returnReason = item.returnReason;
    bundles.push({
      id: 0, // We will set the id later when we add it to the returnableItems array
      items: [
        {
          ...part,
          isUnbundled: true,
          allowFutureReturns: true,
          percentOfBundle: +part.price / totalPrice,
        },
      ],
      lineItemSource: item,
      isExpanded: false,
      rules: bundle.rules,
      value: bundle.value,
      lineItemSourceIndex: LINE_ITEM_SOURCE_INDEX,
    });
  }

  return bundles;
};

async function createExpandedBundle(
  item: any,
  bundle: Bundle,
  order: OrderWithReturnableItems,
  settings: ReturnAppSettings,
): Promise<ExpandedBundle> {
  const productVariants: ProductVariant[] = (
    await getProductsByVariantIds(bundle.parts.map((part) => part.variantId))
  ).data;
  const distributionMethod = getDistributionMethod(settings);
  const parts = [];
  for (const productVariant of productVariants) {
    const part = {
      variant_id: parseGid(productVariant.legacyResourceId),
      id: item.id,
      variant_title: productVariant.title,
      sku: productVariant.sku,
      price: productVariant.price,
      image: {
        src:
          productVariant.image?.url ||
          productVariant.product.featuredImage?.url,
      },
      admin_graphql_api_id: productVariant.product.id,
      title: productVariant.product.title,
      discount_allocations: [],
      fulfillment_status: item.fulfillment_status,
      product_id: parseGid(productVariant.product.id),
      tags: item.tags,
    };
    const itemData = getItemData(part, distributionMethod, order);
    const quantity = bundle.parts.filter(
      (bundlePart) => part.variant_id === bundlePart.variantId,
    ).length;
    parts.push(...Array(quantity).fill(itemData));
  }

  return {
    id: item.id,
    items: parts,
    lineItemSource: item,
    bundledItem: item,
    isExpanded: true,
    returningParts: false,
    partsToReturn: [],
  };
}

// We are taking one line item and splitting it into multiple returnable items
async function createExpandedBundleFromRules(
  item: any,
  matchedProperty: ShopifyProperty,
  bundleRules: BundleRules,
  order: OrderWithReturnableItems,
  settings: ReturnAppSettings,
): Promise<ExpandedBundle> {
  const items = [];
  if (bundleRules.valueType === BundleValueType.SKU) {
    const parsedValue = parseValue(matchedProperty.value, bundleRules);
    const parsedValueList = parsedValue.split(bundleRules.valueDelimiter);
    const response = await getProductsBySkus(parsedValueList);
    const distributionMethod = getDistributionMethod(settings);

    for (const product of response.data) {
      if (product.product.status !== "ACTIVE") {
        continue;
      }
      const part = {
        variant_id: parseInt(product.legacyResourceId),
        id: item.id,
        variant_title: product.title,
        sku: product.sku,
        price: product.price,
        image: {
          src: product.image?.url || product.product.featuredImage?.url,
        },
        admin_graphql_api_id: product.product.id,
        title: product.product.title,
        discount_allocations: [],
        fulfillment_status: item.fulfillment_status,
        product_id: parseGid(product.product.id),
        tags: item.tags,
      };
      items.push(getItemData(part, distributionMethod, order));
    }
  }

  return {
    id: item.id,
    rules: bundleRules,
    value: matchedProperty.value,
    items,
    lineItemSource: item,
    bundledItem: item,
    isExpanded: true,
    returningParts: false,
    partsToReturn: [],
  };
}

const containsBundleKey = async (
  item,
  bundleRules: BundleRules,
): Promise<ShopifyProperty> => {
  if (bundleRules.isProperty) {
    const matchedProperty: ShopifyProperty = item.properties.find(
      (property: { name: string }) => property.name === bundleRules.key,
    );
    if (matchedProperty) {
      return matchedProperty;
    }
  } else {
    switch (bundleRules.valueType) {
      case BundleValueType.SKU:
        if (item.sku.includes(bundleRules.key)) {
          return {
            name: bundleRules.key,
            value: item.sku,
          };
        }
      // TODO: potentially implement other types here?
    }
  }

  return null;
};

const containsBundleValue = (item, value: string, bundleRules: BundleRules) => {
  let containsBundleValue = false;
  const parsedValue = parseValue(value, bundleRules);
  const parsedValueList = parsedValue.split(bundleRules.valueDelimiter);
  for (const value of parsedValueList) {
    switch (bundleRules.valueType) {
      case BundleValueType.SKU:
        containsBundleValue = item.sku === value;
        break;
      case BundleValueType.VARIANT_ID:
        containsBundleValue = item.variant_id == value;
        break;
      case BundleValueType.BUNDLE_ID:
        containsBundleValue =
          item.properties.find(
            (property: ShopifyProperty) => property.name === bundleRules.key,
          )?.value === value;
        break;
    }
    if (containsBundleValue) {
      break;
    }
  }

  return containsBundleValue;
};

const getReturnableQuantity = (order: OrderWithReturnableItems, lineItem) => {
  const numReturned = lineItem.returns.filter(
    (return_) => !return_.isUnbundled,
  ).length;

  lineItem.numRefunded = 0;
  for (const refund of order.shopify.refunds) {
    if (
      // if the return was created by us skip. otherwise count those returns against the total
      lineItem?.returns.some((return_) =>
        return_.refunds?.some((r) => r.id === refund.id),
      ) ||
      refund.transactions?.some(
        (transaction: { source_name: string }) =>
          transaction.source_name === REDO_APP_ID,
      )
    ) {
      // The refund was issued by us, so don't count it (we already
      // accounted for it in the numReturned variable above)
      continue;
    }
    for (const refunded_item of refund.refund_line_items) {
      if (refunded_item.line_item_id === lineItem.id) {
        lineItem.numRefunded += refunded_item.quantity;
      }
    }
  }

  // initialize the returnable quantity to the number of items that has been fulfilled
  let returnableQuantity;
  if (lineItem?.fulfillment_status === "partial") {
    // If the item is partially fulfilled, we can only return the fulfilled quantity which is the quantity minus the fulfillable quantity
    returnableQuantity = lineItem.quantity - lineItem.fulfillable_quantity;
  } else if (lineItem?.fulfillment_status === "fulfilled") {
    returnableQuantity = lineItem.quantity;
  } else {
    return 0;
  }

  // then subtract the number of items already returned
  returnableQuantity -= numReturned + lineItem.numRefunded;

  return returnableQuantity > 0 ? returnableQuantity : 0;
};

// e.g. [{ item: "a", quantity: 2 }] => [{ item: "a", quantity: 1 }, { item: "a", quantity: 1 }]
function separateByQuantity(
  order: OrderWithReturnableItems,
  lineItems: Record<string, any>[],
) {
  return lineItems.flatMap((item) =>
    Array(getReturnableQuantity(order, item)).fill({
      ...item,
      quantity: 1,
      originalQuantity: item.quantity,
    }),
  );
}

const getBundleMaxQuantity = (bundle: ReturnableItem) => {
  switch (true) {
    case !bundle.rules:
      return 1;
    case bundle.rules.valueType === BundleValueType.BUNDLE_ID:
      return Infinity;
    default:
      return 2;
  }
};

const canAddToBundle = (item, bundle: ReturnableItem) => {
  if (!bundle.rules || bundle.items.length >= getBundleMaxQuantity(bundle)) {
    return false;
  }

  return containsBundleValue(item, bundle.value, bundle.rules);
};

const addToExistingBundle = (
  item,
  bundles: ReturnableItem[],
  returnType: Type,
) => {
  for (const bundle of bundles) {
    if (
      !(bundle.rules as ExpandBundleRules)?.variantExchangeOnly ||
      returnType === "claim"
    ) {
      continue;
    }
    if (canAddToBundle(item, bundle)) {
      bundle.items.push(item);
      setLineItemSource(bundle);
      return true;
    }
  }

  return false;
};

export const setLineItemSource = (returnableItem: ReturnableItem) => {
  if (returnableItem.isExpanded) {
    returnableItem.lineItemSource = returnableItem.bundledItem;
  } else if (returnableItem.isExpanded === false) {
    returnableItem.lineItemSource =
      returnableItem.items[returnableItem.lineItemSourceIndex];
    const combinedProductTags = new Set<string>();
    for (const item of returnableItem.items) {
      item.tags.forEach((tag: string) => combinedProductTags.add(tag));
    }
    returnableItem.lineItemSource.tags = [...combinedProductTags];
  }
};

//TODO: Make it so only bundle items use bundled types instead of all items
export async function getReturnableItems(
  order: OrderWithReturnableItems,
  settings: ReturnAppSettings,
  bundleRulesList: BundleRules[],
  returnType: Type,
): Promise<ReturnableItem[] | undefined> {
  try {
    const returnableItems: ReturnableItem[] = [];
    const unmatchedItems = [];
    const items = separateByQuantity(order, order.line_items);
    for (const lineItem of items) {
      if (
        listsHaveMatchingElement(
          lineItem.tags || [],
          settings.portalExcludedProductTags || [],
        )
      ) {
        continue;
      }
      let matchedRules: BundleRules | undefined;
      let matchedProperty: ShopifyProperty | null;
      for (const bundleRules of bundleRulesList) {
        matchedProperty = await containsBundleKey(lineItem, bundleRules);
        if (matchedProperty) {
          matchedRules = bundleRules;
          break;
        }
      }
      const matchedBundle = settings.bundles?.find(
        (bundle) =>
          String(bundle.parentVariantId) === String(lineItem.variant_id) &&
          bundle.allowedTypes.includes(
            (returnType as ReturnTypeName) || ReturnTypeName.RETURN,
          ),
      );
      if (matchedRules || matchedBundle) {
        if (matchedRules?.type === BundleType.EXPAND || matchedBundle) {
          let bundle: ExpandedBundle;
          if (matchedBundle) {
            bundle = await createExpandedBundle(
              lineItem,
              matchedBundle,
              order,
              settings,
            );
          } else {
            bundle = await createExpandedBundleFromRules(
              lineItem,
              matchedProperty,
              matchedRules,
              order,
              settings,
            );
          }
          if (
            !(matchedRules as ExpandBundleRules)?.variantExchangeOnly ||
            (returnType === "claim" && bundle.items.length > 0)
          ) {
            unbundleItems(lineItem, bundle).forEach((itemInBundle) => {
              const quantityReturned = lineItem.returns.filter(
                (return_) =>
                  return_.variant_id == itemInBundle.items[0].variant_id,
              ).length;
              const quantityInBundle =
                bundle.items.filter(
                  (item) => item.variant_id == itemInBundle.items[0].variant_id,
                ).length * lineItem.originalQuantity;
              const quantityAdded = returnableItems.filter(
                (returnableItem) =>
                  returnableItem.items[0].variant_id ==
                  itemInBundle.items[0].variant_id,
              ).length;
              if (quantityInBundle > quantityReturned + quantityAdded) {
                itemInBundle.items[0].discount_price =
                  ReturnTotalsCalculator.getDiscountPrice(
                    lineItem,
                    order,
                    undefined,
                    itemInBundle.items[0],
                  );
                returnableItems.push({
                  ...itemInBundle,
                  id: returnableItems.length,
                });
              }
            });
          } else {
            returnableItems.push(bundle);
          }
        } else {
          const addedToBundle = addToExistingBundle(
            lineItem,
            returnableItems,
            returnType,
          );
          if (!addedToBundle) {
            const bundle: MergedBundle = {
              id: returnableItems.length,
              rules: matchedRules,
              value: matchedProperty.value,
              items: [lineItem],
              lineItemSource: lineItem,
              lineItemSourceIndex: LINE_ITEM_SOURCE_INDEX,
              isExpanded: false,
            };
            returnableItems.push(bundle);
          }
        }
      } else {
        unmatchedItems.push(lineItem);
      }
    }

    for (const item of unmatchedItems) {
      const addedToBundle = addToExistingBundle(
        item,
        returnableItems,
        returnType,
      );
      if (!addedToBundle) {
        const bundle: MergedBundle = {
          id: returnableItems.length,
          items: [item],
          lineItemSource: item,
          lineItemSourceIndex: LINE_ITEM_SOURCE_INDEX,
          isExpanded: false,
        };
        returnableItems.push(bundle);
      }
    }

    return returnableItems;
  } catch (e) {
    console.error(e);
    // FIXME Should we throw the error or just return undefined?
    return undefined;
  }
}

export const setFieldOnItemBeingReturned = <T>(
  returnableItem: ReturnableItem,
  field: string,
  value: T,
) => {
  for (const item of returnableItem.items) {
    item[field] = value;
  }
  if (returnableItem.isExpanded) {
    for (const part of returnableItem.partsToReturn) {
      part[field] = value;
    }
    returnableItem.bundledItem[field] = value;
  }
};

export const getBundleTotalPriceBeforeDiscount = (bundle: ReturnableItem) => {
  if (bundle.isExpanded) {
    return parseFloat(bundle.bundledItem.price);
  } else {
    return bundle.items.reduce(
      (acc: number, item: any) => acc + parseFloat(item.price),
      0,
    );
  }
};

export const getBundleTotalDiscountPrice = (bundle: ReturnableItem) => {
  if (bundle.isExpanded) {
    return parseFloat(bundle.bundledItem.discount_price);
  } else {
    return bundle.items.reduce(
      (acc: number, item: any) => acc + parseFloat(item.discount_price),
      0,
    );
  }
};

export function listsHaveMatchingElement(
  list1: string[],
  list2: string[],
): boolean {
  return list1.some((key) =>
    list2.some((attribute) => attribute.toLowerCase() === key.toLowerCase()),
  );
}

export function isItemRemovedFromOrder(lineItem: LineItem): boolean {
  return (
    lineItem.fulfillable_quantity === 0 &&
    lineItem.fulfillment_status !== "fulfilled"
  );
}

export function getProductsFromReturnableItems(
  returnableItems: ReturnableItem[],
): ReturnableItem["items"] {
  const items: ReturnableItem["items"] = [];
  for (const returnableItem of returnableItems) {
    if (!returnableItem.isExpanded) {
      returnableItem.items.forEach((item) => items.push(item));
    } else if (returnableItem.returningParts) {
      returnableItem.partsToReturn.forEach((part) => items.push(part));
    } else {
      items.push(returnableItem.bundledItem);
    }
  }

  return items;
}

export const exchangeGroupFilter = (
  product,
  exchangeGroup: ExchangeGroup,
  collectionProducts = null,
) => {
  if (!exchangeGroup) {
    return true;
  }
  switch (exchangeGroup.type) {
    case "tag:tag":
      return product.tags.some((tag: string) =>
        exchangeGroup.targetTags.includes(tag),
      );
    case "tag:collection":
    case "name:collection":
      return collectionProducts?.some(
        (collectionProduct) => collectionProduct.id === product.id,
      );
  }
};

export const isVariantExchange = (item: {
  new_item?: any;
  exchangeGroupItem?: any;
}) => {
  return item.new_item || item.exchangeGroupItem;
};

// FIXME These types are broken - getLocations return a list of LocationData, but LocationData doesn't have fields like country_name, etc
async function getFulfilledFromAddress(
  locationId: string,
  shopifyLocations: { data: { locations: any[] } },
): Promise<Address> {
  const response = shopifyLocations || (await getLocations());

  const location = response.data.locations.find(
    (location) => location.id === locationId,
  );
  if (location) {
    return {
      city: location.city,
      country: location.country,
      country_name: location.country_name,
      name: location.name,
      phone: location.phone,
      state: location.province_code,
      street1: location.address1,
      street2: location.address2,
      zip: location.zip,
    };
  } else {
    // No original location
    return null;
  }
}

function getAddress(
  address: Address,
  originalAddress: Address,
  originalLocation: boolean,
): Address {
  if (originalLocation && originalAddress) {
    return originalAddress;
  }
  return address;
}

function sameProvince(provinceCode: string, customerProvinceString: string) {
  customerProvinceString = upperCase(customerProvinceString.trim());
  if (provinceCode === customerProvinceString) {
    return true;
  }
  if (
    upperCase(USA_PROVINCES_BY_CODE[provinceCode]) === customerProvinceString
  ) {
    return true;
  }
  return false;
}

type MultipleChoiceAnswer = {
  answer: string;
  questionText: string;
};

export async function getMerchantAddress({
  locationSettings,
  productTags,
  returnReason,
  fulfilledFromLocationId,
  multipleChoiceAnswers,
  returnType,
  productId,
  orderTags,
  customerTags,
  discounts,
  price,
  sku,
  salesChannel,
  customerCountry,
  customerProvince,
  shopifyLocations,
  hasFinalSaleCoverage,
}: {
  locationSettings: ShippingLocation[];
  productTags: string[];
  returnReason: string | null;
  fulfilledFromLocationId: string;
  multipleChoiceAnswers: MultipleChoiceAnswer[];
  returnType: "return" | "claim" | "warranty" | "managed_claim";
  productId: string;
  orderTags: string[];
  customerTags: string[];
  discounts: DiscountName[];
  price: string | null;
  sku: string;
  salesChannel: string;
  customerCountry: string;
  customerProvince: string;
  shopifyLocations?: { data: { locations: any[] } };
  hasFinalSaleCoverage: boolean;
}): Promise<Address> {
  const fulfilledFromLocation = await getFulfilledFromAddress(
    fulfilledFromLocationId,
    shopifyLocations,
  );
  let collections = [];
  for (const location of locationSettings) {
    if (location.condition == LocationCondition.COLLECTIONS) {
      const response = await getCollectionsForProduct(productId);
      collections = response.data || [];
    }
    if (!location.flowTypes.includes(returnType)) {
      continue;
    }
    switch (location.condition) {
      case LocationCondition.PRODUCT_TAGS: {
        const locationTags = location.tags.map((tag: string) =>
          tag.toLowerCase(),
        );
        const matchingTags = productTags.filter((tag: string) =>
          locationTags.includes(tag.toLowerCase()),
        );
        if (matchingTags.length > 0) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      }
      case LocationCondition.MULTIPLE_CHOICE: {
        const matchingQuestion = multipleChoiceAnswers.find(
          (answer) => answer.questionText === location.multipleChoiceQuestion,
        );
        if (
          matchingQuestion &&
          location.multipleChoiceQuestionAnswers.includes(
            matchingQuestion.answer,
          )
        ) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      }
      case LocationCondition.RETURN_REASON:
        if (location.returnReasons.includes(returnReason)) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      case LocationCondition.DAMAGED:
        if (isDamaged(returnReason)) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      case LocationCondition.NAME:
        if (fulfilledFromLocation?.name === location.address.name) {
          return location.address;
        }
        break;
      case LocationCondition.COLLECTIONS:
        if (
          location.collections.some((collection) =>
            collections.includes(collection),
          )
        ) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      case LocationCondition.ORDER_TAGS: {
        const locationOrderTags = location.tags.map((tag: string) =>
          tag.toLowerCase(),
        );
        const orderMatchingTags = orderTags.filter((tag: string) =>
          locationOrderTags.includes(tag.toLowerCase()),
        );
        if (orderMatchingTags.length > 0) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      }
      case LocationCondition.CUSTOMER_TAGS: {
        const locationCustomerTags = location.tags.map((tag: string) =>
          tag.toLowerCase(),
        );
        const customerMatchingTags = customerTags.filter((tag: string) =>
          locationCustomerTags.includes(tag.toLowerCase()),
        );
        if (customerMatchingTags.length > 0) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      }
      case LocationCondition.DISCOUNTS: {
        const names = new Set(
          location.discounts.map((name) => name.toLowerCase()),
        );
        const foundMatchingDiscount = discounts.some(
          (discount) =>
            names.has(discount.code.toLowerCase()) ||
            names.has(discount.title.toLowerCase()),
        );
        if (foundMatchingDiscount) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      }
      case LocationCondition.PRICE: {
        let matchesCondition = false;
        switch (location.priceMatchType) {
          case PriceOption.EQUALS:
            matchesCondition = price === location.price;
            break;
          case PriceOption.LESS_THAN:
            matchesCondition = parseFloat(price) < parseFloat(location.price);
            break;
          case PriceOption.GREATER_THAN:
            matchesCondition = parseFloat(price) > parseFloat(location.price);
            break;
          case PriceOption.CENTS_EQUALS:
            // Just get the cents values from each.
            matchesCondition =
              parseFloat(price).toFixed(2).slice(-2) ===
              parseFloat(location.price).toFixed(2).slice(-2);
            break;
        }
        if (matchesCondition) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      }
      case LocationCondition.SKU: {
        let matchesSku = false;
        switch (location.skuMatchType) {
          case SkuOption.EQUALS:
            matchesSku = location.skus.includes(sku.toLowerCase());
            break;
          case SkuOption.STARTS_WITH:
            matchesSku = location.skus.some((code) => {
              return sku.toLowerCase().startsWith(code);
            });
            break;
          case SkuOption.ENDS_WITH:
            matchesSku = location.skus.some((code) => {
              return sku.toLowerCase().endsWith(code);
            });
            break;
          case SkuOption.INCLUDES:
            matchesSku = location.skus.some((code) => {
              return sku.toLowerCase().includes(code);
            });
            break;
        }
        if (matchesSku) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      }
      case LocationCondition.SALES_CHANNEL: {
        let found = false;
        SalesChannels.forEach((channel: Channel) => {
          if (location.salesChannels.includes(channel.id)) {
            channel.sourceNames.forEach((sourceName: string) => {
              if (salesChannel.startsWith(sourceName)) {
                found = true;
              }
            });
          }
        });
        if (found) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      }
      case LocationCondition.CUSTOMER_COUNTRY:
        if (location.countries.includes(customerCountry)) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      case LocationCondition.CUSTOMER_ADDRESS:
        if (
          location.provinces.country === customerCountry &&
          location.provinces.provinceCodes.some((provinceCode) =>
            sameProvince(provinceCode, customerProvince),
          )
        ) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      case LocationCondition.FINAL_SALE_RETURNS:
        if (hasFinalSaleCoverage) {
          return getAddress(
            location.address,
            fulfilledFromLocation,
            location.originalLocation,
          );
        }
        break;
      default:
        return getAddress(
          location.address,
          fulfilledFromLocation,
          location.originalLocation,
        );
    }
  }
  // if we didn't match any of the locations, return the default location (the last one)
  const defaultLocation = locationSettings[locationSettings.length - 1];
  return getAddress(
    defaultLocation.address,
    fulfilledFromLocation,
    defaultLocation.originalLocation,
  );
}

/**
 * Example usage:
 *  array: ["a", "b", "c"], conjunction: "or" => "a, b, or c"
 *  array: ["a", "b"], conjunction: "and" => "a and b"
 *  array: ["a"], conjunction: "and" => "a"
 */
export const arrayToString = (array: string[], conjunction: string): string => {
  switch (array.length) {
    case 0:
      return "";
    case 1:
      return array[0];
    case 2:
      return `${array[0]} ${conjunction} ${array[1]}`;
    default: {
      let output = array.slice(0, array.length - 1).join(", ");
      output += `, ${conjunction} ${array[array.length - 1]}`;
      return output;
    }
  }
};

export const redirectToWebsite = (
  settings: ReturnAppSettings,
  window: React.MutableRefObject<Window>,
  price?: number,
  selectedProduct?: {
    url: string;
  },
) => {
  let path = "/apps/redo/exchange-select";
  let delimiter = "?";
  // If they have a custom storefront URL, make sure we send them there.
  if (settings.exchanges.shopSiteURL) {
    path += `?productUrl=${settings.exchanges.shopSiteURL}`;
    delimiter = "&";
  } else if (selectedProduct?.url) {
    path += `?productUrl=${selectedProduct.url}`;
    delimiter = "&";
  }

  if (price) {
    path += `${delimiter}exchangeValue=${price}`;
  }

  // must happen synchronously to avoid popup blockers
  const w = globalThis.open(
    new URL(
      path,
      `https://${settings.exchanges.shopSiteDomain || settings.storeUrl}`, // THIS DOES NOT HIT LOCAL HOST
    ),
  );
  if (w) {
    w.onunload = () => {
      if (w.closed) {
        window.current = undefined;
      }
    };
    // save for use by advanced-exchange
    window.current = w;
  }
};

export const getDaysHoursMinutesSecondsFromMs = (milliseconds: number) => {
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
  return {
    fullFormat: `${days}d ${hours}h ${minutes}m ${seconds}s`,
    highestValueFormat:
      days > 0
        ? `${days} day${days !== 1 ? "s" : ""}`
        : hours > 0
          ? `${hours} hour${hours !== 1 ? "s" : ""}`
          : minutes > 0
            ? `${minutes} minute${minutes !== 1 ? "s" : ""}`
            : `${seconds} second${seconds !== 1 ? "s" : ""}`,
  };
};

export const redirectToWebsiteWithDiscountCode = ({
  discountCode,
  utmSource,
  utmMedium,
  utmContent,
  utmTerm,
  settings,
  window,
  selectedProduct,
}: {
  discountCode: string;
  utmSource?: string;
  utmMedium?: string;
  utmContent?: string;
  utmTerm?: string;
  settings: ReturnAppSettings;
  window: React.MutableRefObject<Window>;
  selectedProduct?: {
    url: string;
  };
}) => {
  const url = new URL(
    `https://${
      settings.exchanges.shopSiteDomain || settings.storeUrl
    }/apps/redo/discount-code`,
  );
  if (selectedProduct?.url) {
    url.searchParams.set("productUrl", selectedProduct.url);
  } else if (settings.exchanges.shopSiteURL) {
    // If they have a custom storefront URL, make sure we send them there.
    url.searchParams.set("productUrl", settings.exchanges.shopSiteURL);
  }

  if (utmSource) {
    url.searchParams.set("utm_source", utmSource);
  }
  if (utmMedium) {
    url.searchParams.set("utm_medium", utmMedium);
  }
  if (utmContent) {
    url.searchParams.set("utm_content", utmContent);
  }
  if (utmTerm) {
    url.searchParams.set("utm_term", utmTerm);
  }
  if (discountCode) {
    url.searchParams.set("discountCode", discountCode);
  }

  // must happen synchronously to avoid popup blockers
  const w = globalThis.open(url);
  if (w) {
    w.onunload = () => {
      if (w.closed) {
        window.current = undefined;
      }
    };
    // save for use by advanced-exchange
    window.current = w;
  }
};

type Variant = {
  inventory_management?: string;
  inventory_quantity?: number;
  inventory_policy?: string;
};

export const isVariantInStock = (
  variant: Variant,
  settings: ReturnAppSettings,
) => {
  return (
    variant.inventory_management === null ||
    continueIfOutOfStock(variant, settings) ||
    (variant.inventory_quantity || 0) >= settings.inventory.minimum_stock
  );
};

export const continueIfOutOfStock = (
  variant: Variant,
  settings: ReturnAppSettings,
) => {
  return (
    settings.inventory.followShopifyInventoryPolicy &&
    variant.inventory_policy?.toLowerCase() === "continue"
  );
};
