import { ZodError } from "zod";
import {
  InferRpcDefinition,
  RpcClientDefinition,
  RpcDefinition,
} from "./definition";
import { ClientError, serializeError } from "./errors";
import { dehydrate, hydrate } from "./wire-protocol";

export type RpcClient<D extends RpcClientDefinition> = {
  [key in keyof D]: (
    input: D[key]["input"],
    signal?: AbortSignal,
    options?: RpcOptions,
  ) => Promise<D[key]["output"]>;
};

export type ClientOptions<D extends RpcDefinition> = {
  baseURL: URL;
  headers?: Record<string, string>;
  onError?: (rpcName: keyof D, error: unknown) => Promise<void> | void;
};

export type RpcOptions = {
  requestHeaders?: Record<string, string>;
};

export function createRpcClient<D extends RpcDefinition>(
  def: D,
  { baseURL, headers, onError }: ClientOptions<D>,
): RpcClient<InferRpcDefinition<D>> {
  const client = {} as RpcClient<InferRpcDefinition<D>>;
  for (const key in def) {
    const rpc = def[key];
    if (!rpc) {
      continue;
    }
    const url = new URL(
      `${baseURL.pathname.replace(/\/$/, "")}/${key}`,
      baseURL,
    );

    client[key] = async (params, signal, options) => {
      try {
        let input;
        try {
          const zod = rpc.input.safeParse(params);
          if (!zod.success) {
            throw zod.error;
          }
          input = dehydrate({ input: zod.data });
        } catch (error) {
          throw new Error(
            `RPC Input Validation Error ${key}: ${serializeError(error)}`,
          );
        }
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...headers,
            ...(options?.requestHeaders ?? {}),
          },
          body: JSON.stringify(input),
          signal,
        });
        if (!response.ok) {
          throw response;
        }
        const json: unknown = await response.json();
        if (!json || typeof json !== "object" || !("output" in json)) {
          throw new TypeError("Network response did not contain RPC output");
        }

        let output;
        try {
          const hydratedOutput = hydrate(json.output);
          const zod = rpc.output.safeParse(hydratedOutput);
          if (!zod.success) {
            throw zod.error;
          }
          output = zod.data;
        } catch (error) {
          throw new Error(
            `RPC Output Validation Error ${key}: ${serializeError(error)}`,
          );
        }

        return output;
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          throw new Error(`Request aborted for RPC method ${key}`);
          return; // Optionally return a default value or handle the abort case
        }

        await onError?.(key, error);
        if (error instanceof Response) {
          let message: string | undefined;
          if (error.headers.get("Content-Type")?.includes("application/json")) {
            const json: unknown = await error.json();
            message =
              json && typeof json === "object"
                ? "error" in json
                  ? String(json.error)
                  : "message" in json
                    ? String(json.message)
                    : undefined
                : undefined;
          } else {
            message = await error.text();
          }
          throw new ClientError(error.status, message ?? "Unknown error");
        } else if (error instanceof ZodError) {
          throw new Error(
            `RPC Validation Error ${key}: ${serializeError(error)}`,
          );
        }
        // throw new Error(error.toString());
        throw error;
      }
    };
  }
  return client;
}
